<!-- name -->

<mat-stepper linear #stepper>
  <mat-step [stepControl]="personal_info_fg" state="person">
    <form class="form registration-form" [formGroup]="personal_info_fg">
      <ng-template matStepLabel>{{ plugin.personal_info_title }}</ng-template>
      <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
      <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>

      <mat-form-field>
        <mat-label>Voornaam</mat-label>
        <input formControlName="first_name" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Naam</mat-label>
        <input formControlName="last_name" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input formControlName="email" type="email" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Telefoonnummer</mat-label>
        <input formControlName="tel" type="tel" matInput />
      </mat-form-field>
      <button matStepperNext>Verder</button>
    </form>

    <!-- <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Fill out your name</ng-template>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="firstCtrl" placeholder="Last name, First name" required>
      </mat-form-field>
      <div>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form> -->
  </mat-step>

  <mat-step [stepControl]="company_info_fg" state="business">
    <form class="form registration-form" [formGroup]="company_info_fg">
      <ng-template matStepLabel>{{ plugin.company_info_title }}</ng-template>
      <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
      <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>

      <h2>{{ plugin.company_info_title }}</h2>
      <mat-form-field>
        <mat-label>BTW-nummer</mat-label>
        <input formControlName="vat_number" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Bedrijfsnaam</mat-label>
        <input formControlName="name" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Website</mat-label>
        <input formControlName="website" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>IBAN</mat-label>
        <input formControlName="iban" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>BIC</mat-label>
        <input formControlName="bic" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Straat</mat-label>
        <input formControlName="street" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Huisnummer</mat-label>
        <input formControlName="street_nr" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Busnummer/Toevoeging</mat-label>
        <input formControlName="street_suffix" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Postcode</mat-label>
        <input formControlName="postal_code" type="text" matInput />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Stad</mat-label>
        <input formControlName="city" type="text" matInput />
      </mat-form-field>

      <button mat-button matStepperPrevious>Terug</button>
      <button mat-button (click)="createPaymentIntent()">Verder</button>
    </form>
  </mat-step>

  <mat-step state="paid">
    <ng-template matStepLabel>Betaling</ng-template>

    <ngx-stripe-elements
      *ngIf="elementsOptions.clientSecret"
      [stripe]="stripe"
      [elementsOptions]="elementsOptions">
      <ngx-stripe-payment [options]="paymentElementOptions" />
    </ngx-stripe-elements>

    <button mat-button matStepperPrevious>Terug</button>
    <!-- TODO: add validation -->
    <button mat-button (click)="confirmPayment()">Next</button>
  </mat-step>

  <mat-step [stepControl]="account_fg" state="password">
    <form class="form registration-form" [formGroup]="account_fg">
      <ng-template matStepLabel>{{ plugin.account_info_title }}</ng-template>

      <mat-form-field>
        <mat-label>Wachtwoord</mat-label>
        <input formControlName="password" type="password" matInput />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Herhaal wachtwoord</mat-label>
        <input formControlName="password_confirmation" type="password" matInput />
      </mat-form-field>

      <div class="error-message" *ngIf="account_fg.errors?.['PasswordNoMatch']">
        Wachtwoorden komen niet overeen
      </div>

      <mat-checkbox formControlName="conditions">
        Ik begrijp dat ik door verder te gaan een abonnement start en ga akkoord met de
        <a routerLink="algemene-voorwaarden">Algemene Voorwaarden</a>.
      </mat-checkbox>

      <mat-checkbox formControlName="marketing_mails_consent">
        Ik wil marketing communicatie van V-NS ontvangen
      </mat-checkbox>

      <button type="submit" (click)="completeRegistration()">Verder</button>

      <button matStepperNext>Verder</button>
    </form>
  </mat-step>

  <mat-step state="checklist">
    <ng-template matStepLabel>Klaar</ng-template>
    <p>{{ plugin.successMessage }}</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step>
</mat-stepper>

<!-- Step Icon Templates -->
<ng-template matStepperIcon="person">
  <mat-icon>person</mat-icon>
</ng-template>

<ng-template matStepperIcon="business">
  <mat-icon>business</mat-icon>
</ng-template>

<ng-template matStepperIcon="paid">
  <mat-icon>paid</mat-icon>
</ng-template>

<ng-template matStepperIcon="password">
  <mat-icon>password</mat-icon>
</ng-template>

<ng-template matStepperIcon="checklist">
  <mat-icon>checklist</mat-icon>
</ng-template>
