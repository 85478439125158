import { TicketStatus } from './back-office.models';

//#region User
export interface LoginRequestDTO {
  email: string;
  password: string;
}

export type AdminRole = 'admin' | 'jurist';
export type UserRole = 'admin' | 'jurist' | 'member' | 'third-party';

export interface VNSUserBase {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  active: boolean;
}

/**
 * Users
 */
export type AdminUserType = 'admin' | 'jurist';
export type MemberUserType = 'member';
export type UserType = AdminUserType | MemberUserType;

export interface Admin extends VNSUserBase {
  role: AdminUserType;
  password_hash: string;
  refresh_token: string;
}

export interface Member extends VNSUserBase {
  // role: MemberUserType;
  subscription_id: string;
  company_id: string;
  company_name: string;
  started_date: Date;
  next_renewal_date: Date;
  updated_at: Date;
  password_hash: string;
  refresh_token: string;
  third_parties?: unknown[];
}
//#endregion

//#region Registration
export interface RegisterDTO {
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  tel: string;
  website?: string;
  // adres
  street: string;
  street_nr: string;
  street_suffix: string | null;
  postal_code: string;
  city: string;

  iban: string; // TODO: validate
  bic: string;
  vat_number: string;
  remarks?: string;
  tags?: string[];
  conditions: boolean;
  marketing_mails_consent: boolean;
  password: string;
  // een referentie van een bestaand lid, verkoper of een korting code bij inschrijving
  reference_code?: string;
  // paymentIntentId: string;
}

export interface RegisterResultDTO {
  success: boolean;
  message: string;
}

//#endregion

//#region Auth
export type MemberDTO = Omit<Member, 'password_hash' | 'refresh_token'>;
export interface MemberSignInResultDTO {
  access_token: string;
  refresh_token: string;
  member: MemberDTO;
}
export type AdminDTO = Omit<Admin, 'password_hash' | 'refresh_token'>;
export type JuristDetailDTO = AdminDTO & {
  tickets: {
    id: string;
    title: string;
    requested_at: string;
    owner_id: string;
    status: TicketStatus;
    updated_at: string | null;
    assignee_id: string | null;
  }[];
};
export interface AdminSignInResultDTO {
  access_token: string;
  refresh_token: string;
  admin: AdminDTO;
}
export type AuthenticatedUserRole = 'admin' | 'jurist' | 'member';
export interface JwtPayload {
  /**
   * subject identifier
   */
  sub: string;
  /**
   * subject name
   */
  name: string;
  /**
   * issuer
   */
  iss: string;
  /**
   * audience
   */
  aud: string;
  /**
   * issued at (number of seconds)
   */
  iat: number;
  /**
   * subject role
   */
  role: AuthenticatedUserRole;
}
//#endregion
