import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OnboardingForm } from './OnboardingForm';

export class ResetPasswordForm extends OnboardingForm {
  get data(): FormData {
    const data = new FormData();
    data.append('email', this.formGroup.controls['email'].value);
    return data;
  }

  constructor(fb: FormBuilder) {
    super();
    this.formGroup = fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }
}
