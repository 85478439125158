export * from './lib/vns-ng-core.module';
export * from './lib/components';

export { AuthService } from './lib/auth.service';
export { AuthGuardService } from './lib/auth-guard.service';
export { SocketsService } from './lib/sockets.service';

export { VNS_ENVIRONMENT } from './lib/config';
export { UnauthorizedInterceptor } from './lib/interceptors/unauthorized.interceptor';
export * from './lib/store/user.reducer';
export * from './lib/store/login.effects';
export * from './lib/store/selectors';
export * from './lib/store/index';
export { VNSCoreConfig } from './lib/VNSCoreConfig';
export * from './lib/components';
// TODO: read this to also export base scss and images
// https://coco-boudard.medium.com/adding-scss-assets-to-angular-library-33a9ed0654fa
