import { createSelector } from '@ngrx/store';
import { CoreState } from '..';
import { FEATURE_KEY } from '../../config';

export const selectNotifications = (state: { [FEATURE_KEY]: CoreState }) =>
  state[FEATURE_KEY].notifications;

export const selectNotificationQueue = createSelector(
  selectNotifications,
  (state) => state.queue
);
export const selectNextNotification = createSelector(
  selectNotifications,
  (state) => (state.queue.length > 0 ? state.queue[0] : undefined)
);
