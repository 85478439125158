import { Injectable } from '@angular/core';
// eslint-disable-next-line no-var
declare var gtag: any;
// https://medium.com/@danielfilipkowskiblogs/adding-google-analytics-to-angular-v15-1766c4bdaed5
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  trackEvent(eventName: string, eventDetails: string, eventCategory: string) {
    gtag('event', eventName, {
      // event Type - example: 'SCROLL_TO_TOP_CLICKED'
      event_category: eventCategory,
      // the label that will show up in the dashboard as the events name
      event_label: eventName,
      // a short description of what happened
      value: eventDetails,
    });
  }
}
