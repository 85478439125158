export interface IUserProfileApiResponse {
  success: boolean;
  type: 'get-profile';
  profile: {
    id: number;
    prefered_language: string; // 'nl';
    roles_id: number;
    roles_name: string; //'Developer';
    job_title: string; //'Developer';
    first_name: string; // 'Cigar';
    last_name: string; // 'Agency';
    email: string; // 'support@cigar.agency';
    gsm: string; // '0472224361';
    tel: string; // '';
    twoFaEnabled: boolean;
    divisions: [
      {
        roles_id: number; //1;
        divisions_id: number; //1;
        divisions_name: string; // 'cigar.agency';
        roles_name: string; // 'Developer';
        id: number; // 1;
      },
    ];
  };
  avatar: string; // 'https://secure.gravatar.com/avatar/c8aa7a6a5cce944a767ef6d0f0959cd2?s=150&d=identicon';
  debug: boolean; // true;
}
