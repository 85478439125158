import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY } from './config';
import { ROOT_REDUCER } from './store';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MediaListComponent } from './media-list/media-list.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CutCellTextDirective } from './directives/cut-cell-text.directive';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileDragDropComponent } from './file-drag-drop/file-drag-drop.component';
import { BytePipe } from './pipes/byte.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { LoggingService } from './logging.service';
import { QueryParametersService } from './query-parameters.service';
import { VATCheckService } from './services/vat-check/vat-check.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, ROOT_REDUCER),
    MatListModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
  ],
  declarations: [
    FileUploaderComponent,
    DragAndDropDirective,
    MediaListComponent,
    CutCellTextDirective,
    FileDragDropComponent,
    BytePipe,
    AddressPipe,
  ],
  providers: [LoggingService, QueryParametersService, VATCheckService],
  exports: [
    FileDragDropComponent,
    BytePipe,
    AddressPipe,
    FileUploaderComponent,
    MediaListComponent,
    CutCellTextDirective,
  ],
})
export class CaNgCoreModule {}
