import { createAction, props } from '@ngrx/store';
import { TwoFaRequestActionData, LoginSuccess } from '../../types';
import { OnboardingAction } from '../config';

export const twoFaRequest = createAction(
  OnboardingAction.TWO_FA_REQUEST,
  props<TwoFaRequestActionData>()
);
export const twoFaSuccess = createAction(
  OnboardingAction.TWO_FA_SUCCESS,
  props<LoginSuccess>()
);
