import { Routes } from '@angular/router';
import {
  RegisterComponent,
  SetPasswordComponent,
  LoginComponent,
  ResetPasswordComponent,
  EditProfileComponent,
} from '../components';
import { ONBOARDING_APP_ROUTES } from '../config';

export const ONBOARDING_ROUTES: Routes = [
  {
    path: ONBOARDING_APP_ROUTES.REGISTER,
    component: RegisterComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.ACCESS,
    component: SetPasswordComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.LOGIN,
    component: LoginComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.RESETPASS,
    component: ResetPasswordComponent,
  },
  {
    path: ONBOARDING_APP_ROUTES.PROFILE,
    component: EditProfileComponent,
  },
  {
    path: 'access',
    redirectTo: ONBOARDING_APP_ROUTES.ACCESS,
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: ONBOARDING_APP_ROUTES.LOGIN,
    pathMatch: 'full',
  },
  {
    path: 'reset-password',
    redirectTo: ONBOARDING_APP_ROUTES.RESETPASS,
    pathMatch: 'full',
  },
];
