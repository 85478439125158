import { createAction, props } from '@ngrx/store';
import {
  ResetPasswordRequest,
  ResetPasswordResponse,
  OnboardingError,
  SetPasswordActionData,
} from '../../types';
import { OnboardingAction } from '../config';

/**
 * Reset Password
 */
export const resetPassword = createAction(OnboardingAction.RESET_PASSWORD);
export const resetPasswordRequest = createAction(
  OnboardingAction.RESET_PASSWORD_REQUEST,
  props<ResetPasswordRequest>(),
);
export const resetPasswordSuccess = createAction(
  OnboardingAction.RESET_PASSWORD_SUCCESS,
  props<ResetPasswordResponse>(),
);
export const resetPasswordFailure = createAction(
  OnboardingAction.RESET_PASSWORD_FAILURE,
  props<OnboardingError>(),
);

export const setPassword = createAction(
  OnboardingAction.SET_PASSWORD_REQUEST,
  props<SetPasswordActionData>(),
);

export const setPasswordSuccess = createAction(
  OnboardingAction.SET_PASSWORD_SUCCESS,
);
export const setPasswordFailure = createAction(
  OnboardingAction.SET_PASSWORD_SUCCESS,
  props<OnboardingError>(),
);
