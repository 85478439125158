import { ISnackbarConfiguration } from './ISnackbarConfiguration';
import { ISnackbarServiceConfiguration } from './ISnackbarServiceConfiguration';

export class SnackbarServiceConfiguration {
  constructor(config: Partial<ISnackbarServiceConfiguration> = {}) {
    this.errorSnackbar = {
      defaultButtonText: 'OK',
      panelClass: 'error-snackbar',
      ...config.errorSnackbar,
    };
    this.infoSnackbar = {
      defaultButtonText: 'OK',
      panelClass: 'info-snackbar',
      ...config.infoSnackbar,
    };
    this.successSnackbar = {
      defaultButtonText: 'OK',
      panelClass: 'success-snackbar',
      ...config.successSnackbar,
    };
  }

  errorSnackbar!: ISnackbarConfiguration;
  infoSnackbar!: ISnackbarConfiguration;
  successSnackbar!: ISnackbarConfiguration;
}
