import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileValidators } from '../validators';

@Component({
  selector: 'ca-file-uploader',
  template: ` <ca-file-drag-drop
    [formControl]="fileControl"
    activeBorderColor="#3F51B5"
    [multiple]="multiple"
    (valueChanged)="onValueChange($event)"
    [emptyPlaceholder]="placeholder"
    class="ca-file-uploader"
  >
  </ca-file-drag-drop>`,
  // TODO: prevent cutoff on placeholder text
  styles: ['ngx-file-drag-drop {height: fit-content;}', 'ngx-file-drg-drop.ca-file-uploader { .placeholder { overflow: auto !important; }}'],
})
export class FileUploaderComponent implements OnInit {
  @Output() filesDropped = new EventEmitter<FilesDroppedEventData & { trackingId: string }>();
  @Input() companyId!: number;
  @Input() parentTable!: string;
  @Input() context!: string;
  // optional
  @Input() multiple = true;
  @Input() parentId?: number;
  // TODO: localize!
  @Input() placeholder = `Drop file${this.multiple ? 's' : ''} or click to select`;

  fileControl = new FormControl<File[]>([], [FileValidators.required, FileValidators.maxFileCount(2)]);

  onValueChange(files: File[]) {
    if (files.length > 0) {
      const eventId = crypto.randomUUID();
      const eventPayload = {
        companyId: this.companyId,
        parentTable: this.parentTable,
        parentId: this.parentId,
        context: this.context,
        files: files,
        multiple: this.multiple,
        trackingId: eventId,
      };
      this.filesDropped.emit(eventPayload);
    }
  }

  ngOnInit(): void {
    if (this.parentTable === undefined || this.context === undefined || this.companyId === undefined || this.companyId <= 0) {
      throw new Error(`Please provide all the required inputs to this component: [companyId, parentTable, context]`);
    }
    this.fileControl.valueChanges.subscribe((files: File[] | null) => {
      if (files) console.log(this.fileControl.value, this.fileControl.valid);
    });
  }
}

export interface FilesDroppedEventData {
  files: File[];
  companyId: number;
  parentTable: string;
  context: string;
  multiple: boolean;
  parentId?: number;
  trackingId: string;
}
