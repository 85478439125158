import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { FEATURE_KEY } from '../../store';
import { UserState } from '../../store/user.reducer';

@Component({
  selector: 'ca-forbidden',
  template: `
    <div class="error">
      <h1>U bent niet gemachtigt om de pagina te bezoeken.</h1>
      <ng-container *ngIf="error$ | async as err">
        <p *ngIf="err.message" class="error-message">{{ err.message }}</p>
        <div *ngIf="err.html" [innerHtml]="err.html"></div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .error {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .error-message {
        align-self: flex-start;
      }
    `,
  ],
})
export class ForbiddenComponent {
  error$ = this.store.select((s) => s[FEATURE_KEY].user.error);
  // TODO: get html from CMS
  constructor(
    // private _location: Location,
    private store: Store<{ [FEATURE_KEY]: { user: UserState } }>
  ) {}
}
