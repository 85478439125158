import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, catchError } from 'rxjs';
import * as moment from 'moment';
import { ForbiddenHttpRequest, UnauthorizedHttpRequest, UserState } from '../store/user.reducer';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private router: Router, private store: Store<{ user: UserState }>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(async (error) => {
        if (error.status === 401) {
          console.error('Unauthorized API request', moment().toISOString());
          this.store.dispatch(
            UnauthorizedHttpRequest({
              url: request.urlWithParams,
              error: error,
            })
          );
        }
        if (error.status === 403) {
          console.error('Forbidden API request', moment().toISOString());
          // TODO: create action
          this.store.dispatch(
            ForbiddenHttpRequest({
              error: error,
              url: request.urlWithParams,
              message: 'U bent niet gemachtigd om deze pagina te bekijken.',
              html: `
                <p>Dit is een test</p>
                <ol>
                  <li>een</li>
                  <li>twee</li>
                </ol>
              `,
            })
          );
          // route to forbidden page with
          // TODO: do this in app effects
          this.router.navigate(['/forbidden']);
        }
        throw error;
      })
    );
  }
}
