import { createAction, props } from '@ngrx/store';

import {
  SubmitLoginFormActionData,
  LoginSuccess,
  SubmitFormSuccessActionData,
  OnboardingError,
} from '../../types';
import { OnboardingAction } from '../config';

/**
 * User clicks login button and submits validated login form.
 */
export const submitLoginForm = createAction(
  OnboardingAction.SUBMIT_LOGIN_FORM,
  props<SubmitLoginFormActionData>()
);
export const submitLoginFormSuccess = createAction(
  OnboardingAction.SUBMIT_LOGIN_FORM_SUCCESS,
  props<SubmitFormSuccessActionData>()
);
export const submitLoginFormFailure = createAction(
  OnboardingAction.SUBMIT_LOGIN_FORM_FAILURE,
  props<OnboardingError>()
);

export const loginSuccess = createAction(
  OnboardingAction.LOGIN_SUCCESS,
  props<LoginSuccess>()
);
export const loginFailure = createAction(
  OnboardingAction.LOGIN_FAILURE,
  props<OnboardingError>()
);

export const togglePasswordVisibility = createAction(
  OnboardingAction.TOGGLE_PASSWORD_VISIBILITY,
  props<{ hide: boolean }>()
);

export const logout = createAction(OnboardingAction.LOGOUT);
