import { IUserProfile } from '../V2';

export interface ILoginSuccessApiResponse {
  success: boolean;
  type: string; //'post-login';
  debug: boolean;
  twoFaEnabled: boolean;
  twoFaDeviceLinked: boolean;
  bearerToken: string;
  profile?: IUserProfile;
  avatar?: string;
  twoFaQrCodeUrl?: string;
}
