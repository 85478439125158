import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreYouSureDefaultComponent } from './are-you-sure-default-overlay/are-you-sure-default-overlay.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import { AreYouSureOverlayComponent } from './are-you-sure-overlay.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
  declarations: [AreYouSureDefaultComponent, AreYouSureOverlayComponent],
  exports: [AreYouSureDefaultComponent, AreYouSureOverlayComponent],
})
export class CaAreYouSureModule {}
