import { Injectable } from '@angular/core';
import { RegistrationsService } from '../../services/registrations.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SubmitRegistrationForm } from '../reducers/forms.reducer';
import { firstValueFrom, switchMap } from 'rxjs';
import { RegisterSuccess, RegisterFailure } from '@ca/vns-ng-core';

@Injectable()
export class RegistrationEffects {
  constructor(private readonly actions$: Actions, private registrations: RegistrationsService) {}
  onSubmitRegistrationForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubmitRegistrationForm),
      switchMap((dto) =>
        firstValueFrom(this.registrations.post(dto))
          .then((res) => RegisterSuccess({ req: dto, res }))
          .catch((error) => RegisterFailure({ error }))
      )
    )
  );
}
