import { NewsArticle, NewsGalleryPlugin, PageID } from '@ca/vns-models';
import { createReducer, on } from '@ngrx/store';

import { LoadPageSuccess } from './pages.reducer';

export interface NewsState {
  articles: NewsArticle[];
}
const initialState: NewsState = { articles: [] };
export const reducer = createReducer(
  initialState,
  on(LoadPageSuccess, (_, { res }) =>
    res.id === PageID.Nieuws
      ? {
          articles: MapNewsArticles(
            (res.plugins.find((p) => p.type === 'news-gallery') as NewsGalleryPlugin | undefined)
              ?.articles
          ),
        }
      : _
  )
);
function MapNewsArticles(articles?: NewsArticle[]) {
  articles?.map((a) => {
    const article = { ...a };

    const tag_groups = [...article.tag_groups].map((tg) => ({
      ...tg,
      tags: [...tg.tags].sort((a, b) => a.name.localeCompare(b.name)),
    }));
    // .forEach((tg) => {
    //   tg.tags = [...tg.tags].sort((a, b) => a.name.localeCompare(b.name));
    //   return tg;
    // });

    return {
      ...article,
      tag_groups,
    };
  });
  return articles ?? [];
}
