import { ILinkedDivision, IUserDivision, IUserProfile, IUserRole } from '../V2';

export interface UserState {
  profile?: IUserProfile;
  token?: string;
  avatarUrl?: string;
  users?: IUserProfile[];
  divisions?: IUserDivision[];
  roles?: IUserRole[];
  activeDivision?: ILinkedDivision;
  hasMultipleDivisions: boolean;
}
