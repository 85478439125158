<div *ngIf="profile" class="user-profile-form onboarding-container">
  <mat-tab-group class="tabs-container">
    <mat-tab label="Personal info" class="edit-user-tab">
      <div class="personal-info">
        <form
          *ngIf="profileFormGroup"
          class="user-profile-personal-info-form personal-info-side"
          [formGroup]="profileFormGroup"
          (ngSubmit)="updatePersonalInfo()"
        >
          <mat-form-field class="full-width">
            <mat-label>{{ msgs.form.firstname }}</mat-label>
            <input matInput formControlName="firstName" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ msgs.form.lastname }}</mat-label>
            <input matInput formControlName="lastName" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ msgs.form.email }}</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ msgs.form.mobile }}</mat-label>
            <input matInput formControlName="mobile" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ msgs.form.telephone }}</mat-label>
            <input matInput formControlName="tel" />
          </mat-form-field>

          <div class="actions">
            <button mat-raised-button color="primary">
              <mat-icon>save</mat-icon>
              {{ msgs.form.submit_button_text }}
            </button>
          </div>
        </form>

        <div class="personal-info-side">
          <ca-user-profile-card [profile]="profile"></ca-user-profile-card>
        </div>
      </div>
    </mat-tab>

    <!-- SECURITY -->
    <mat-tab label="Security" class="edit-user-tab">
      <div class="user-widget-security-container">
        <mat-slide-toggle
          [(ngModel)]="profile.twoFaEnabled"
          (toggleChange)="toggle2FA()"
        >
          {{ msgs.slider }}
        </mat-slide-toggle>

        <div class="actions">
          <button
            mat-raised-button
            color="warn"
            (click)="resetPassword()"
            [matTooltip]="msgs.reset_button_tooltip"
          >
            <mat-icon>lock_reset</mat-icon>
            {{ msgs.reset_button_text }}
          </button>

          <button
            *ngIf="profile.twoFaEnabled && profile.twoFaDeviceLinked"
            mat-raised-button
            color="warn"
            (click)="reset2FADevice()"
            [matTooltip]="msgs.reset_2fa_button_tooltip"
          >
            {{ msgs.reset_2fa_button_text }}
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
