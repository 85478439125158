import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OnboardingForm } from './OnboardingForm';

export class SetPasswordForm extends OnboardingForm {
  get data(): FormData {
    if (!this.token) throw new Error('Set token before requesting data.');
    const formData = new FormData();
    formData.append('password', this.formGroup.controls['pass'].value);
    formData.append('token', this.token);
    return formData;
  }

  get value() {
    return {
      pass: this.formGroup.controls['pass'].value ?? '',
      token: this.token,
    };
  }

  override get valid() {
    return super.valid && this.passwordsMatch && this.minLengthValid;
  }

  get passwordsMatch() {
    return this.pass == this.confirmation;
  }

  get minLengthValid() {
    return this.pass.length >= this.min;
  }

  private get pass(): string {
    return this.formGroup.controls['pass'].value;
  }

  private get confirmation(): string {
    return this.formGroup.controls['passConfirmation'].value;
  }

  private token?: string;
  private min: number;

  constructor(fb: FormBuilder, minLength: number) {
    super();
    this.min = minLength;
    this.formGroup = fb.group({
      pass: new FormControl('', [Validators.required]),
      passConfirmation: new FormControl('', [Validators.required]),
    });
  }

  setToken(token: string) {
    if (!token) throw new Error('Token was undefined');
    this.token = token;
  }
}
