import { Component, Input } from '@angular/core';
import { TicketStatus } from '@ca/vns-models';

@Component({
  selector: 'ca-ticket-status-chip',
  template: `<mat-chip [class]="status">{{ status }}</mat-chip>`,
  styles: [
    `
      mat-chip {
        span {
          color: white !important;
        }
      }
      mat-chip.Open {
        background-color: blue !important;
      }
      mat-chip.WaitingForVNS {
        background-color: orange !important;
      }
      mat-chip.WaitingForThirdParty {
        background-color: lightgrey !important;
      }
      mat-chip.WaitingForClient {
        background-color: green !important;
      }
      mat-chip.Closed {
        background-color: grey !important;
      }
    `,
  ],
})
export class TicketStatusChipComponent {
  @Input() status: TicketStatus = 'Closed';
}
// 'Open'
//   | 'WaitingForVNS'
//   | 'WaitingForThirdParty'
//   | 'WaitingForClient'
//   | 'Closed'
