import {
  TML_ListResponseData,
  TML_ListDepartmentDTO,
  TML_ListTaxRateDTO,
  TML_ListMailTemplateDTO,
  TML_UserListDTO,
} from '@ca/tml-focus-core';
import { AdminDTO, MemberDTO } from './auth.models';
import { VNSUser } from '@prisma/client';

//#region Subscription Setttings
/**
 * Subscription Settings
 */
export interface SubscriptionSettings {
  tml_billing_days_in_advance: 0 | 7 | 14 | 21 | 28;
  tml_department_id: string;
  tml_payment_days_after_invoice_date: number;
  tml_default_responsible_user_id: string;
  tml_subscription_document_template_id: string;
  tml_subscription_tax_rate_id: string;
  tml_subscription_yearly_fee: number;
}
export type UpdateSubscriptionSettings = Partial<SubscriptionSettings>;
export type SubscriptionSettingsData = SubscriptionSettings & { id: string };

export interface VNSSubscriptionSettingsTMLResourcesForSelect {
  tmlDepartments: TML_ListResponseData<TML_ListDepartmentDTO>;
  tmlTaxRates: TML_ListResponseData<TML_ListTaxRateDTO>;
  tmlMailTemplates: TML_ListResponseData<TML_ListMailTemplateDTO>;
  tmlUsers: TML_ListResponseData<TML_UserListDTO>;
}
//#endregion

//#region Tickets
export type TicketStatus =
  | 'Open'
  | 'WaitingForVNS'
  | 'WaitingForThirdParty'
  | 'WaitingForClient'
  | 'Closed';

export type TicketSummaryAssigneeIncludeDto = Pick<AdminDTO, 'first_name' | 'last_name'>;
export type TicketSummaryOwnerIncludeDto = Pick<
  MemberDTO,
  'first_name' | 'last_name' | 'active' | 'company_name' | 'next_renewal_date'
>;

/**
 * Tickets
 */
export interface Ticket {
  id: string;
  title: string;
  requested_at: string; // DateTime           @default(now())
  updated_at: string; //    DateTime?          @updatedAt()
  owner_id: string; //      String
  status: TicketStatus;
  assignee?: AdminDTO; //      VNSUser ? @relation(fields: [assignee_id], references: [id])
  assignee_id: string | null;

  owner: MemberDTO; //         Member             @relation(fields: [owner_id], references: [id])
  third_parties?: unknown[];
  attachments: TicketAttachment[];
  messages: Message[];
  metaUrl: string;
}

export type TicketSummary = {
  id: string;
  assignee_id: string | null;
  assignee: TicketSummaryAssigneeIncludeDto | null;
  owner_id: string;
  owner: TicketSummaryOwnerIncludeDto;
  requested_at: string;
  status: TicketStatus;
  title: string;
};

export type AgentType = 'member' | 'thirdParty' | 'admin' | 'jurist';

export interface TicketAttachment {
  id: string;
  download_url: string;
  date_added: string;
  filename: string;
  ticket_id: string;
  sent_by_id: string;
  sent_by_name: string;
  sent_by_type: AgentType;
}
//#endregion

//#region Messaging
export interface Message {
  id: string;
  message: string;
  sent_at: string;
  sent_by_name: string;
  sent_by_id: string;
  sent_by_type: AgentType;
  ticket_id: string;
  third_party?: boolean;
}
export type SendMessageWsEvent = Omit<Message, 'id'>;
//#endregion

//#region Assignment of Jurist on ticket
export interface AssignJuristWsEvent {
  ticket_id: string;
  jurist_id: string;
}
export interface AssignJuristResult {
  message: 'Jurist assigned';
}
//#endregion

//#region Jurist Registration
export interface RegisterJuristDto {
  oneTimePassword: string;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
}
//#endregion
