/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { Observable, firstValueFrom } from 'rxjs';

import {
  ISetPasswordApiResponse,
  IResetPasswordApiResponse,
  ICheckTokenApiResponse,
  ILoginSuccessApiResponse,
  IOnboardingRoutes,
  IRegisterFormData,
  IToggle2FAResponse,
  OnboardingState,
} from '../types/interfaces';
import {
  GetDivisions,
  GetRoles,
  GetUserProfileResponse,
  GetUsersResponse,
  PutUpdateProfileRequest,
  PutUpdateProfileResult,
  PutUpdateUserResult,
  PutUpsertUserRequest,
} from '../types/interfaces/V2';
import { OnboardingModuleConfiguration } from '../types/classes/OnboardingModuleConfiguration';
import { LogoutComponent } from '../components';

import { logout } from '../store/actions';

import { CA_ENVIRONMENT, LoggingService } from '@ca/ca-ng-core';
import { SnackbarService } from '@ca/ca-snackbar';
import { CaEnvironment, CaSubscriber } from '@ca/ca-utils';
import { IOnboardingService } from '../types/interfaces/services';
import { getBearerHeaderFromLocalStorage } from './utils';
import { DeleteRequest, DeleteResult } from '@ca/ca-data';
import { ClearNotifications } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService implements IOnboardingService, OnDestroy {
  sub: CaSubscriber = new CaSubscriber();

  private checkTokenSchedule?: ReturnType<typeof setTimeout>;
  private routes!: IOnboardingRoutes;

  //#region Getters
  private get tokenStoreKey() {
    return this.env.sessionStorageBearerKey;
  }

  get hasBearerToken(): boolean {
    const token = this.bearerToken;
    return token !== undefined && token !== '' && token !== null;
  }

  get bearerToken() {
    return sessionStorage.getItem(this.tokenStoreKey);
  }
  //#endregion

  //#region Lifecycle Hooks
  constructor(
    @Inject(CA_ENVIRONMENT) private env: CaEnvironment,
    private configuration: OnboardingModuleConfiguration,
    private http: HttpClient,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    private logger: LoggingService,
    private store: Store<{ onboarding: OnboardingState }>
  ) {
    this.routes = this.configuration.routes;
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  //#endregion

  getUserProfile(): Promise<GetUserProfileResponse> {
    return firstValueFrom(
      this.http.get<GetUserProfileResponse>(
        this.env.apiBaseUrl + this.configuration.routes.profile,
        {
          headers: getBearerHeaderFromLocalStorage(this.tokenStoreKey),
        }
      )
    );
  }

  async putUserProfile(putData: PutUpdateProfileRequest): Promise<boolean> {
    const res = await firstValueFrom(
      this.http.put<PutUpdateProfileResult>(
        this.env.apiBaseUrl + this.configuration.routes.updateProfile,
        putData,
        {
          headers: getBearerHeaderFromLocalStorage(this.tokenStoreKey),
        }
      )
    );
    return res.success;
  }

  async getUsers() {
    const res = await firstValueFrom(
      this.http.get<GetUsersResponse>(this.env.apiBaseUrl + this.routes.users, {
        headers: this.getAuthHeader(),
      })
    );
    return res;
  }

  async checkBearerToken(): Promise<boolean> {
    // check if their is a bearer token
    if (this.bearerToken === null) {
      return false;
    }

    // check if the token is valid
    const isTokenValid = await firstValueFrom(this.heartbeatApiCall())
      .then((res: ICheckTokenApiResponse) => {
        this.logger.log('checking heartbeat');
        if (this.bearerToken !== null && (!res.alive || !res.success))
          this.snackbar.errorSnackbar(this.configuration.messages.sessionExpired);
        return res.success && res.alive;
      })
      .catch((err) => {
        this.logger.logError(err);
        this.snackbar.errorSnackbar(this.configuration.messages.sessionExpired);
        return false;
      });
    return isTokenValid;
  }

  heartbeatApiCall() {
    return this.http.get<ICheckTokenApiResponse>(this.env.apiBaseUrl + this.routes.heartbeat, {
      headers: this.getAuthHeader(),
    });
  }

  setBearerToken(token: string): void {
    sessionStorage.setItem(this.tokenStoreKey, token);
    this.clearTokenSchedule();
    this.scheduleTokenCheck();
  }

  getAuthHeader(): HttpHeaders {
    return this.bearerToken
      ? getBearerHeaderFromLocalStorage(this.tokenStoreKey)
      : new HttpHeaders();
  }

  register = (formData: IRegisterFormData): Observable<any> =>
    this.http.post(this.env.apiBaseUrl + this.routes.register, formData);

  login = (formData: FormData): Observable<ILoginSuccessApiResponse> =>
    this.http.post<ILoginSuccessApiResponse>(
      this.env.apiBaseUrl + this.routes.login,
      formData as FormData
    );

  resetPassword = (formData: FormData): Observable<IResetPasswordApiResponse> =>
    this.http.post<IResetPasswordApiResponse>(
      this.env.apiBaseUrl + this.routes.resetPassword,
      formData as FormData
    );

  setPassword = (data: { token: string; pass: string }): Observable<ISetPasswordApiResponse> => {
    const formData = new FormData();
    formData.set('password', data.pass);
    formData.set('token', data.token);

    return this.http.post<ISetPasswordApiResponse>(
      this.env.apiBaseUrl + this.routes.setPassword,
      formData as FormData
    );
  };

  logout(requireConfirmation = false): void {
    this.store.dispatch(ClearNotifications());
    if (requireConfirmation) {
      const _dialog = this.dialog.open(LogoutComponent);
      firstValueFrom(_dialog.afterClosed()).then((res: any) => {
        if (res) this._logout();
      });
    } else this._logout();
  }

  toggle2FA(enable: boolean): Observable<IToggle2FAResponse> {
    return this.http.put<IToggle2FAResponse>(
      this.env.apiBaseUrl + this.routes.toggle2FA,
      { activate: enable ? 1 : 0 },
      {
        headers: getBearerHeaderFromLocalStorage(this.tokenStoreKey),
      }
    );
  }

  validate2FA(
    authenticatorCode: FormData
  ): Observable<ILoginSuccessApiResponse & { valid: boolean }> {
    return this.http.post<ILoginSuccessApiResponse & { valid: boolean }>(
      this.env.apiBaseUrl + this.routes.check2FA,
      authenticatorCode,
      {
        headers: getBearerHeaderFromLocalStorage(this.tokenStoreKey),
      }
    );
  }

  getDivisions(): Observable<GetDivisions> {
    return this.http.get<GetDivisions>(this.env.apiBaseUrl + this.configuration.routes.divisions, {
      headers: getBearerHeaderFromLocalStorage(this.tokenStoreKey),
    });
  }
  getRoles(): Observable<GetRoles> {
    return this.http.get<GetRoles>(this.env.apiBaseUrl + this.configuration.routes.roles, {
      headers: getBearerHeaderFromLocalStorage(this.tokenStoreKey),
    });
  }

  upsertUser(update: PutUpsertUserRequest): Observable<PutUpdateUserResult> {
    return this.http.put<PutUpdateUserResult>(
      this.env.apiBaseUrl +
        this.configuration.routes.users +
        (update.id !== 0 && update.id !== '0' ? '/' + update.id : ''),
      update,
      {
        headers: getBearerHeaderFromLocalStorage(this.tokenStoreKey),
      }
    );
  }
  removeUser(args: DeleteRequest): Observable<DeleteResult> {
    return this.http.delete<DeleteResult>(
      this.env.apiBaseUrl + this.configuration.routes.users + '/' + args.id,
      {
        headers: getBearerHeaderFromLocalStorage(this.tokenStoreKey),
      }
    );
  }

  private _logout() {
    this.endSession();
    this.clearTokenSchedule();
    this.store.dispatch(logout());
  }

  private endSession(): void {
    sessionStorage.clear();
  }

  //#region Token Schedule for heartbeat
  scheduleTokenCheck(intervalMs = this.configuration.checkTokenIntervalMs): void {
    this.logger.log('scheduling token check');
    this.checkTokenSchedule = setInterval(
      () =>
        this.checkBearerToken().then((alive: boolean) => {
          if (!alive) {
            this.logger.log('Token check failed, ending session...');
            this.endSession();
            this.clearTokenSchedule();
            // this.store.dispatch()
          }
        }),
      intervalMs
    );
  }

  private clearTokenSchedule(): void {
    this.logger.log('clearing schedule for token check');
    if (this.checkTokenSchedule != undefined) clearInterval(this.checkTokenSchedule);
  }
  //#endregion
}
