import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setPassword } from '../../store/actions';
import {
  ISetPasswordMessages,
  SetPasswordForm,
  ISetPasswordApiResponse,
  OnboardingModuleConfiguration,
  OnboardingState,
  OnboardingError,
} from '../../types';
import { Actions, ofType } from '@ngrx/effects';
import { OnboardingAction } from '../../store/config';
import { isOnboardingError } from '../../store/utils';
import { LoggingService } from '@ca/ca-ng-core';
import { SnackbarService } from '@ca/ca-snackbar';
import { CaSubscriber } from '@ca/ca-utils';

@Component({
  selector: 'ca-set-password',
  template: `
    <div class="login-form-container">
      <div class="content">
        <div class="header">
          <h1 class="form-title">{{ msgs.title }}</h1>
          <p class="form-instructions">{{ msgs.form_instructions }}</p>
        </div>

        <form
          class="onboarding-form login-form"
          [formGroup]="setPasswordForm.formGroup"
          (ngSubmit)="onSubmit()"
        >
          <mat-form-field>
            <mat-label>{{ msgs.form.password }}</mat-label>
            <input
              matInput
              formControlName="pass"
              [type]="hide ? 'password' : 'text'"
            />
            <mat-icon matSuffix (click)="hide = !hide">{{
              hide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ msgs.form.password_confirmation }}</mat-label>
            <input
              matInput
              formControlName="passConfirmation"
              [type]="hide ? 'password' : 'text'"
            />
            <mat-icon matSuffix (click)="hide = !hide">{{
              hide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </mat-form-field>

          <button mat-button type="submit" class="success">
            {{ msgs.form.submit_button_text }}
          </button>
        </form>
      </div>
    </div>
    <div class="loginPicture"></div>
  `,
})
export class SetPasswordComponent implements OnInit, OnDestroy {
  hide = true;
  msgs: ISetPasswordMessages;
  setPasswordForm: SetPasswordForm;

  private sub: CaSubscriber = new CaSubscriber();

  private setPasswordResultObserver = {
    next: (res: ISetPasswordApiResponse | OnboardingError) => {
      if (isOnboardingError(res)) this.setPasswordForm.reset();
    },
  };

  constructor(
    private config: OnboardingModuleConfiguration,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private snackbar: SnackbarService,
    private router: Router,
    private logger: LoggingService,
    private store: Store<{ onboarding: OnboardingState }>,
    private actions$: Actions
  ) {
    this.logger.log('set password init');
    this.msgs = this.config.messages.setPassword;
    this.setPasswordForm = new SetPasswordForm(
      this.fb,
      this.config.minPasswordLength
    );
    this.sub.subscribe(
      this.actions$.pipe(
        ofType(
          OnboardingAction.SET_PASSWORD_FAILURE,
          OnboardingAction.SET_PASSWORD_SUCCESS
        )
      ),
      this.setPasswordResultObserver
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  ngOnInit(): void {
    this.sub.subscribe(this.route.queryParams, {
      next: (params) => {
        if (!params['t']) throw new Error('Token not found.');
        else this.setPasswordForm.setToken(params['t']);
      },
    });
  }

  onSubmit(): void {
    if (this.setPasswordForm.valid) {
      const data: { token: string; pass: string } = this.setPasswordForm
        .value as { token: string; pass: string };
      this.store.dispatch(setPassword({ data: data }));
    } else if (!this.setPasswordForm.passwordsMatch) {
      this.snackbar.errorSnackbar(this.msgs.passwords_dont_match);
    } else if (!this.setPasswordForm.minLengthValid) {
      this.snackbar.errorSnackbar(
        this.msgs.minLengthError(this.config.minPasswordLength)
      );
    } else {
      this.snackbar.errorSnackbar(this.msgs.form.invalid);
    }
  }
}
