import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAreYouSureOverlay } from '../interfaces/IAreYouSureOverlay';

@Component({
  selector: 'ca-are-you-sure-default',
  template: ` <h1 mat-dialog-title>
      <mat-icon *ngIf="config.icon" class="mat-icon-inline">{{
        config.icon
      }}</mat-icon>
      {{ config.title }}
    </h1>
    <div mat-dialog-content>
      <p class="ays-confirm-question">
        {{ config.confirmQuestion }}
      </p>
      <ng-content></ng-content>
    </div>
    <div mat-dialog-actions align="end">
      <a class="warning" (click)="config.close(false)">
        {{ config.cancelButtonText }}
      </a>
      <button mat-raised-button class="danger" (click)="config.close(true)">
        {{ config.confirmButtonText }}
      </button>
    </div>`,
  styles: [
    `
      .ays-confirm-question {
        word-wrap: true;
        max-width: 30rem;
      }
      a.warning {
        text-decoration: underline;
        margin-right: 2rem;
      }
    `,
  ],
})
export class AreYouSureDefaultComponent {
  config: IAreYouSureOverlay = {
    cancelButtonText: $localize`:@@ays-default-no:NO`,
    close: (choice: boolean) => this.dialogRef.close(choice),
    confirmButtonText: $localize`:@@ays-default-yes:YES`,
    confirmQuestion: $localize`:@@ays-default-question:Are you sure you want to proceed?`,
    icon: undefined,
    title: $localize`:@@ays-default-title:Confirmation`,
    validate: (choice: boolean) => choice != undefined && true,
  };

  constructor(
    public dialogRef: MatDialogRef<AreYouSureDefaultComponent, boolean>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { config?: Partial<IAreYouSureOverlay> }
  ) {
    this.config = { ...this.config, ...this.dialogData.config };
  }
}
