import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import { TwoFactorAuthSetupComponent } from '../../components';
import { TWO_FA_DIALOG_WIDTH } from '../../config';
import { OnboardingService } from '../../services';
import {
  ILoginSuccessApiResponse,
  SubmitLoginFormActionData,
  OnboardingModuleConfiguration,
  SubmitFormSuccessActionData,
  LoginSuccess,
  OnboardingState,
} from '../../types';
import { submitLoginFormSuccess, loginSuccess, UnauthorizedPageLoad } from '../actions';
import { OnboardingAction } from '../config';
import { OnboardingEffects } from './onboarding.effects';
import { SnackbarService } from '@ca/ca-snackbar';
import { CA_ENVIRONMENT, LoggingService } from '@ca/ca-ng-core';
import { Store } from '@ngrx/store';
import { CaEnvironment } from '@ca/ca-utils';

@Injectable()
export class LoginEffects extends OnboardingEffects {
  /**
   * Redirects to login on logout.
   */
  private logoutObserver = {
    next: () => {
      // clear bearer token
      sessionStorage.removeItem(this.env.sessionStorageBearerKey);
      this.logger.log('NAVIGATING TO LOGIN');
      // don't do this if route is login
      this.router.navigate([this.config.appRoutes.login]);
    },
  };

  /**
   * Sets bearer token in session storage and redirects to success route.
   */
  private loginObserver = {
    next: (res: LoginSuccess) => {
      if (res.token) this.svc.setBearerToken(res.token);
      if (res.profile && res.token) {
        if (res.redirectTo) {
          const [params, query] = res.redirectTo.split('?');
          console.log(params, query);
          const segments = params.split('/');
          if (query) {
            const queryParams = { [query.split('=')[0]]: query.split('=')[1] };
            this.router.navigate(segments, queryParams);
            console.log('redirecting to', params, queryParams);
          } else this.router.navigate(segments);
        } else this.router.navigate([this.config.appRoutes.redirectOnSuccess]);
      }
    },
  };

  get minAccessLvl() {
    return this.config.requiredAccessLevel ?? 10;
  }

  constructor(
    @Inject(CA_ENVIRONMENT) private readonly env: CaEnvironment,
    protected override config: OnboardingModuleConfiguration,
    protected override actions$: Actions,
    protected override router: Router,
    protected override svc: OnboardingService,
    protected override logger: LoggingService,
    protected override snackbar: SnackbarService,
    protected override store: Store<{ onboarding: OnboardingState }>,
    private dialog: MatDialog
  ) {
    super(config, svc, actions$, router, snackbar, logger, store);
    this.sub.subscribe(
      this.actions$.pipe(ofType(OnboardingAction.LOGIN_SUCCESS)),
      this.loginObserver
    );

    this.sub.subscribe(this.actions$.pipe(ofType(OnboardingAction.LOGOUT)), this.logoutObserver);
    this.sub.subscribe(this.actions$.pipe(ofType(UnauthorizedPageLoad)), {
      next: (v) => {
        this.router
          .navigate([this.config.appRoutes.login], {
            queryParams: { redirectTo: v.url },
          })
          .then((res) => {
            if (res) this.logger.log('redirected to login', v);
            else this.logger.logError('Could not redirect unauthorized user to login', v);
          })
          .catch((err) => {
            this.logger.logError('Could not redirect unauthorized user to login', v);
            this.logger.logError(err);
          });
      },
    });
  }

  /**
   * On Submit Login Form.
   */
  submitLoginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingAction.SUBMIT_LOGIN_FORM),
      switchMap((actionData: SubmitLoginFormActionData) =>
        this.svc.login(actionData.data).pipe(
          map((res: ILoginSuccessApiResponse) => {
            console.log(res);
            return res.success
              ? submitLoginFormSuccess({ response: res, redirectTo: actionData.redirectOnSuccess })
              : this.QueueError(this.config.messages.login.failed);
          }),
          catchError(() => of(this.QueueError(this.config.messages.login.failed)))
        )
      )
    )
  );

  /**
   * Handles login form api response and the 2-FA dialog flow when enabled.
   */
  onSubmitLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingAction.SUBMIT_LOGIN_FORM_SUCCESS),
      switchMap((actionData: SubmitFormSuccessActionData) => {
        const res = actionData.response;
        const requiredDivision = this.config.requiredDivision;
        const hasRequiredAccessLevel =
          res.profile?.linkedDivisions.findIndex((a) =>
            requiredDivision
              ? a.role.accessLevel <= this.minAccessLvl && a.division.id === requiredDivision
              : a.role.accessLevel <= this.minAccessLvl
          ) !== -1;

        if (requiredDivision !== undefined && !hasRequiredAccessLevel)
          return of(this.QueueError(this.config.messages.login.unauthorized));

        return res.twoFaEnabled
          ? this.twoFaDialog(res, actionData.redirectTo)
          : of(
              loginSuccess({
                profile: res.profile,
                avatar: res.avatar,
                token: res.bearerToken,
                redirectTo: actionData.redirectTo,
              })
            );
      })
    )
  );

  private launchTwoFactorDialog(loginRes: ILoginSuccessApiResponse) {
    return this.dialog.open<TwoFactorAuthSetupComponent, ILoginSuccessApiResponse, boolean>(
      TwoFactorAuthSetupComponent,
      {
        width: TWO_FA_DIALOG_WIDTH, // TODO: don't use more space than needed
        data: loginRes,
      }
    );
  }

  private mapTwoFactorDialogResult(
    loginRes: ILoginSuccessApiResponse,
    redirectTo?: string,
    success?: boolean
  ) {
    if (success && success == true) {
      return loginSuccess({
        profile: loginRes.profile,
        avatar: loginRes.profile?.avatar,
        token: loginRes.bearerToken,
        redirectTo,
      });
    } else return this.QueueError(this.config.messages.login.twoFaFailed);
  }

  private twoFaDialog(res: ILoginSuccessApiResponse, redirectTo?: string) {
    if (this.dialog.openDialogs.length > 0) this.dialog.closeAll();
    // show 2FA dialog when enabled
    return this.launchTwoFactorDialog(res)
      .afterClosed()
      .pipe(map((success) => this.mapTwoFactorDialogResult(res, redirectTo, success)));
  }
}
