import { ILoginUIMessages } from './ILoginUIMessages';

export interface ILoginMessages {
  success: string;
  failed: string;
  invalid: string;
  ui: ILoginUIMessages;
  twoFaFailed: string;
  unauthorized: string;
}
