import { Component, Input, OnInit } from '@angular/core';
import { ChatMessage } from './conversation.component';

@Component({
  selector: 'ca-message',
  template: `
    <div
      class="message"
      [class.outgoing]="direction === 'outgoing'"
      [class.incoming]="direction === 'incoming'">
      <p class="body">
        {{ message.message }}
      </p>
      <span class="info"
        >{{ message.sent_at }} - <span class="bold">{{ message.sent_by_name }}</span></span
      >
    </div>
  `,
  styles: [
    `
      .message {
      }
      .body {
        border: 0.5px solid green;
        border-radius: 0.33rem;
        padding: 0.33rem;
      }
      .incoming {
        .body {
          background-color: white;
          color: blue;
        }
        align-self: flex-end;
      }
      .outgoing {
        .body {
          background-color: blue;
          color: white;
        }
        align-self: flex-start;
      }
      .info {
        font-size: 0.6rem;
        text-align: end;
      }
    `,
  ],
})
export class MessageComponent implements OnInit {
  @Input() message!: ChatMessage;
  @Input() direction!: 'incoming' | 'outgoing';
  ngOnInit(): void {
    if (!this.message) throw new Error('Message was not defined!');
    if (!this.direction) throw new Error('Direction was not defined!');
  }
}
