import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import {
  CaNotification,
  CoreState,
  QueueNotification,
  ReadyToBeNotified,
  UnqueueNotification,
  selectNextNotification,
} from '@ca/ca-ng-core';
import { CaSubscriber } from '@ca/ca-utils';
import { FEATURE_KEY } from '@ca/ca-ng-core';
import { Store, select } from '@ngrx/store';
import { concatMap, delay, distinctUntilChanged, filter, firstValueFrom, map } from 'rxjs';
import { SnackbarService } from '../../snackbar.service';
import { Actions, ofType } from '@ngrx/effects';
import { ActionSnackbarComponent } from '../../components/action-snackbar/action-snackbar.component';
export const DEFAULT_SNACKBAR_ACTIONS = [
  {
    text: 'ok',
  },
];
@Injectable()
export class NotificationEffects implements OnDestroy {
  DEFAULT_SNACKBAR_LIFETIME = 3500;
  private sub: CaSubscriber;
  notification?: CaNotification;
  private openSnackbarRef?: MatSnackBarRef<ActionSnackbarComponent>;

  private notificationObserver = {
    next: (notification: CaNotification | undefined) => {
      this.notification = notification;
      if (notification != undefined) {
        const duration = notification.lifetime ?? this.DEFAULT_SNACKBAR_LIFETIME;
        switch (notification.type) {
          case 'error':
          case 'warning':
            this.openSnackbarRef = this.snackbar.errorSnackbar(
              notification.message,
              notification.actions ?? DEFAULT_SNACKBAR_ACTIONS,
              { duration }
            );

            break;

          case 'info':
            this.openSnackbarRef = this.snackbar.infoSnackbar(
              notification.message,
              notification.actions ?? DEFAULT_SNACKBAR_ACTIONS,
              { duration }
            );
            break;

          case 'success':
            this.openSnackbarRef = this.snackbar.successSnackbar(
              notification.message,
              notification.actions ?? DEFAULT_SNACKBAR_ACTIONS,
              { duration }
            );
            break;
          default:
            console.log('Not a know notification type', notification.type, 'color: blue');
        }
        if (this.openSnackbarRef !== undefined && this.notification)
          firstValueFrom(this.openSnackbarRef.afterDismissed()).then((res) => {
            if (this.notification) {
              this.store.dispatch(UnqueueNotification());
              this.notification = undefined;
            }
          });
      }
    },
  };

  constructor(
    private store: Store<{ [FEATURE_KEY]: CoreState }>,
    private snackbar: SnackbarService,
    private actions$: Actions
  ) {
    this.sub = new CaSubscriber();
    this.sub.subscribe(
      this.actions$.pipe(
        ofType(ReadyToBeNotified, QueueNotification),
        concatMap(() => {
          return this.store.pipe(
            select(selectNextNotification),
            filter((notification) => notification != undefined),
            distinctUntilChanged(),
            delay(
              this.notification != undefined
                ? this.notification?.lifetime ?? this.DEFAULT_SNACKBAR_LIFETIME
                : 20
            ),
            map((message: CaNotification | undefined) => message)
          );
        })
      ),
      this.notificationObserver
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
