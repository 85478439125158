export enum PageID {
  Home = 1,
  OverVNS = 2,
  OnzeDiensten = 3,
  Nieuws = 4,
  LidWorden = 5,
  OnzePartners = 6,
  Contact = 7,
  MijnDossier = 8,
  JuridischeDiensten = 9,
  Ondersteuning = 10,
  AdviserendeRol = 11,
  PrivacyAndCookiePolicy = 12,
  MijnVNS = 13,
}

export interface ContactFormDTO {
  first_name: string;
  last_name: string;
  email: string;
  message: string;
}
