import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnboardingState, onboardingActions } from '../../store';

@Component({
  selector: 'ca-logout-button',
  template: `<button id="logout-btn" mat-icon-button (click)="logout()"><mat-icon>logout</mat-icon></button>`,
  styles: [],
})
export class LogoutButtonComponent {
  constructor(private store: Store<{ onboarding: OnboardingState }>) {}
  logout() {
    this.store.dispatch(onboardingActions.logout());
  }
}
