import { Component, Input } from '@angular/core';
import { IUserProfile } from '../../../types/interfaces/V2';

@Component({
  selector: 'ca-user-summary-grid',
  templateUrl: './user-summary-grid.component.html',
  styleUrls: ['./user-summary-grid.component.scss'],
})
export class UserSummaryGridComponent {
  @Input() profile: IUserProfile | null = null;
}
