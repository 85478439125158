import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[caDragAndDrop]',
})
export class DragAndDropDirective {
  @HostBinding('class.fileover') fileOver = false;

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer?.files;
    // if (files && files.length > 0) {
    // console.log(`you dropped ${files.length} files.`);
    // }
    // console.log('Drag leave', evt);
  }
}
