import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { CaSubscriber } from '@ca/ca-utils';
import { LoggingService } from '@ca/ca-ng-core';
import { SnackbarService } from '@ca/ca-snackbar';

import { OnboardingService } from '../../services/onboarding.service';
import {
  resetPassword,
  submitLoginForm,
  submitLoginFormFailure,
  togglePasswordVisibility,
} from '../../store/actions';
import { ILoginUIMessages, OnboardingModuleConfiguration, OnboardingState } from '../../types';

import { selectOnboarding } from '../../store/selectors';
import { CreateError, QueueNotification } from '@ca/ca-ng-core';
import { Actions, ofType } from '@ngrx/effects';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'ca-login',
  template: `
    <div class="login-form-container onboarding-container">
      <div class="content">
        <div class="login-header">
          <h1 class="login-form-title">{{ ui.title }}</h1>
          <p class="login-form-instructions">
            {{ ui.subtitle }}
          </p>
        </div>

        <form class="onboarding-form login-form">
          <mat-form-field class="login-username-form-field">
            <mat-label>{{ ui.label_username }}</mat-label>
            <input matInput type="text" [formControl]="login" id="initialFocusInput" />
          </mat-form-field>

          <mat-form-field class="login-password-form-field">
            <mat-label>{{ ui.label_password }}</mat-label>
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              [formControl]="pass"
              (keyup.enter)="onSubmit()" />
            <mat-icon matSuffix (click)="toggleHide()">{{
              hide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </mat-form-field>
          <br />
          <a (click)="resetPassword()" class="forgot-password-link">
            {{ ui.forgot_password_link_text }}
          </a>
          <br />
          <button mat-raised-button color="primary" (click)="onSubmit()">
            {{ ui.button_text }}
          </button>
        </form>
      </div>
    </div>
    <div class="loginPicture"></div>
  `,
})
export class LoginComponent implements OnInit, OnDestroy {
  login = new FormControl(null, [Validators.required]);
  pass = new FormControl(null, [Validators.required]);
  sub: CaSubscriber = new CaSubscriber();
  hide = true;

  readonly ui: ILoginUIMessages;
  private onboardingObserver = {
    next: (state: OnboardingState) => {
      this.dispatchedLogin = false;
      if (state) {
        this.hide = state.login?.hidePassword ?? true;
        if (state.login?.error) this.dispatchError(state.login.error.message);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: (err: any) => this.dispatchError('Unexpected error occured'),
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  private dispatchError = (message: string) =>
    this.store.dispatch(QueueNotification(CreateError(message, 'LoginComponent')));

  private dispatchedLogin = false;
  //#region Lifecycle Hooks
  constructor(
    private config: OnboardingModuleConfiguration,
    private onboarding: OnboardingService,
    private snackbar: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggingService,
    private store: Store<{ onboarding: OnboardingState }>,
    private actions$: Actions
  ) {
    this.ui = config.messages.login.ui;
  }
  redirectOnSuccess: string | null = null;
  ngOnInit(): void {
    this.sub.subscribe(this.store.select(selectOnboarding), this.onboardingObserver);
    this.sub.subscribe(this.route.queryParamMap, {
      next: (res) => {
        this.logger.log('query', res);
        this.redirectOnSuccess = res.get('redirectTo');
      },
    });

    this.onboarding.checkBearerToken().then(async (e) => {
      if (e == true) {
        const redirectUrl = await firstValueFrom(this.route.queryParamMap).then((query) => {
          return query.get('redirectTo');
        });
        if (redirectUrl) {
          const [params, query] = redirectUrl.split('?');
          const segments = params.split('/');
          if (query) {
            const queryParams = { [query.split('=')[0]]: query.split('=')[1] };
            this.router.navigate(segments, queryParams);
          } else this.router.navigate(segments);
        }
        this.logger.log(
          'redirecting on success',
          redirectUrl ?? this.config.appRoutes.redirectOnSuccess
        );
        this.router.navigate([redirectUrl ?? this.config.appRoutes.redirectOnSuccess]);
      }
    });

    this.sub.subscribe(this.actions$.pipe(ofType(submitLoginFormFailure)), {
      next: () => {
        this.dispatchedLogin = false;
        this.login.setValue(null, {
          emitEvent: false,
          onlySelf: true,
        });
        this.pass.setValue(null, {
          emitEvent: false,
          onlySelf: true,
        });
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  //#endregion

  // TODO: move form field names to module config
  onSubmit(): void {
    if (this.dispatchedLogin) {
      console.log('login already dispatched');
    }
    if (this.login.valid && this.pass.valid && !this.dispatchedLogin) {
      const formData = new FormData();
      formData.append('login', this.login.value ?? '');
      formData.append('pass', this.pass.value ?? '');
      this.dispatchedLogin = true;
      firstValueFrom(this.route.queryParamMap)
        .then((query) => {
          return query.get('redirectTo');
        })
        .then((redirectUrl) => {
          this.store.dispatch(
            submitLoginForm({
              data: formData,
              redirectOnSuccess: redirectUrl ?? this.redirectOnSuccess ?? undefined,
            })
          );
        });
    } else if (!this.dispatchedLogin) {
      this.dispatchError(this.config.messages.login.invalid);
    }
  }

  toggleHide(): void {
    this.store.dispatch(togglePasswordVisibility({ hide: !this.hide }));
  }

  resetPassword() {
    this.store.dispatch(resetPassword());
  }
}
