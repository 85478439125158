import { CreateResultDTO, Message, SendTicketDTO, Ticket, TicketSummary } from '@ca/vns-models';
import { LogoutSuccess } from '@ca/vns-ng-core';
import { createAction, createReducer, on, props } from '@ngrx/store';

export interface MyFileState {
  tickets: TicketSummary[];
  detail?: Ticket;
}
export enum MyFileActions {
  loadTickets = '[Tickets] Load',
  loadTicketsSuccess = '[Tickets] Loaded successfully',
  loadTicketsFailure = '[Tickets] Failed to load',

  loadTicketDetail = '[Ticket Detail] Load',
  loadTicketDetailSuccess = '[Ticket Detail] Loaded successfully',
  loadTicketDetailFailure = '[Ticket Detail] Failed to load',

  sendTicket = '[Ticket] Send',
  sendTicketSuccess = '[Ticket] Sent successfully',
  sendTicketFailure = '[Ticket] Failed to send',

  messageReceived = '[Ticket Messages] Received',
  loadMessageIntoDetail = '[Ticket Message] Load into detail',
}

export const LoadTickets = createAction(MyFileActions.loadTickets);
export const LoadTicketsSuccess = createAction(
  MyFileActions.loadTicketsSuccess,
  props<{
    tickets: TicketSummary[];
  }>()
);
export const LoadTicketsFailure = createAction(
  MyFileActions.loadTicketsFailure,
  props<{ error: any }>()
);

export const LoadTicketDetail = createAction(
  MyFileActions.loadTicketDetail,
  props<{ id: string }>()
);
export const LoadTicketDetailSuccess = createAction(
  MyFileActions.loadTicketDetailSuccess,
  props<{ ticket: Ticket }>()
);
export const LoadTicketDetailFailure = createAction(
  MyFileActions.loadTicketDetailFailure,
  props<{ error: any }>()
);

export const SendTicket = createAction(MyFileActions.sendTicket, props<SendTicketDTO>());
export const SendTicketSuccess = createAction(
  MyFileActions.sendTicketSuccess,
  props<CreateResultDTO>()
);
export const SendTicketFailure = createAction(
  MyFileActions.sendTicketFailure,
  props<{ error: any }>()
);

export const MessageReceived = createAction(
  MyFileActions.messageReceived,
  props<{ message: Message }>()
);
export const LoadMessageIntoDetail = createAction(
  MyFileActions.loadMessageIntoDetail,
  props<{ message: Message }>()
);

export const initialState: MyFileState = {
  tickets: [],
};

export const reducer = createReducer(
  initialState,
  on(LoadTicketsSuccess, (_, { tickets }) => ({
    ..._,
    tickets,
  })),
  on(LoadTicketDetailSuccess, (_, { ticket }) => ({
    ..._,
    detail: ticket,
  })),
  on(LogoutSuccess, (_) => initialState)
);
