import { HTTP_ERROR_CODE } from './enums/HTTP_ERROR_CODE';
import { ICaDataModuleConfiguration } from './interfaces/ICaDataModuleConfiguration';

export type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

export class CaDataModuleConfiguration implements ICaDataModuleConfiguration {
   constructor(options?: Partial<ICaDataModuleConfiguration>) {
      this.httpErrorMessages =
         options?.httpErrorMessages ?? HTTP_ERROR_MESSAGES;
   }
   httpErrorMessages: EnumDictionary<number, string>;
}

// DEFAULT
export const HTTP_ERROR_MESSAGES: EnumDictionary<number, string> = {
   0: 'An unexpected error occured...',
   [HTTP_ERROR_CODE.BAD_REQUEST]:
      'The request was badly formatted, therefore it could not be processed correctly.',
   [HTTP_ERROR_CODE.UNAUTHORIZED]: 'Unauthorized request.',
   [HTTP_ERROR_CODE.INSUFFICIENT_PERMISSIONS]:
      "You don't have sufficient permissions to excecute this action.",
   [HTTP_ERROR_CODE.NOT_FOUND]: 'The resource could not be found.',
   [HTTP_ERROR_CODE.CONFLICT]:
      'A confict prevented the execution of this action.',
   [HTTP_ERROR_CODE.NAME_NOT_UNIQUE]:
      'This resource requires a unique name. The name that was provided is already in use.',
   [HTTP_ERROR_CODE.INTERNAL_SERVER_ERROR]:
      'An unknown error occured on the server. Please try again later.',
   [HTTP_ERROR_CODE.DISSALLOWED_MERGE_TAG]:
      'Dissalowed merge tag used in template, make sure to only use merge tags from the dropdown in the e-mail editor.',
};

export const HTTP_ERROR_MESSAGES_NL: EnumDictionary<number, string> = {
   0: 'Er is een onbekende fout opgetreden.',
   [HTTP_ERROR_CODE.BAD_REQUEST]:
      'De request werd niet juist geformatteerd en kon hierdoor niet worden uitgevoerd door de server.',
   [HTTP_ERROR_CODE.UNAUTHORIZED]:
      'Je moet (opnieuw) inloggen om deze actie te kunnen uitvoeren.',
   [HTTP_ERROR_CODE.INSUFFICIENT_PERMISSIONS]:
      'Je bent niet gemachtigd om deze bewerking uit te voeren.',
   [HTTP_ERROR_CODE.NOT_FOUND]:
      'De bewerking kon niet worden uitgevoerd omdat de resource niet kon worden gevonden.',
   [HTTP_ERROR_CODE.CONFLICT]:
      'De bewerking kon niet worden uitgevoerd door een conflicterende bewerking die voorrang kreeg.',
   [HTTP_ERROR_CODE.INTERNAL_SERVER_ERROR]:
      'Er is een onbekende fout opgetreden op de server. Probeer het later nog eens.',
};
