export interface ITwoFaDialogMessages {
  ui: {
    title: string;
    input_code_label: string;
    qr_code_label: string;
    code_input_label: string;
    whoops: string;
    fetch_code_error: string;
    back_button_text: string;
    proceed_button_text: string;
    failed_snackbar: string;
    two_step_failed: string;
    invalid_token: string;
  };
}
