<header>
  <div id="logo"></div>
  <!-- TODO: ngIf for members-/guestOnly -->
  <div class="nav-container">
    <nav class="protected">
      <ng-container *ngIf="isLoggedIn">
        <ng-container *ngFor="let route of nav.secondaryMenuLinks">
          <a [routerLink]="route.metaUrl">{{ route.menuName }}</a>
        </ng-container>
        <a [matMenuTriggerFor]="settingsMenu">Mijn V-NS</a>
        <mat-menu #settingsMenu>
          <button *ngFor="let l of nav.dropdownLinks" mat-menu-item [routerLink]="l.metaUrl">
            {{ l.menuName }}
          </button>
          <button mat-menu-item (click)="logOut()">Afmelden</button>
        </mat-menu>
      </ng-container>
    </nav>

    <nav class="public">
      <ng-container *ngFor="let route of nav.primaryMenuLinks">
        <a
          *ngIf="!route.guestOnly || (route.guestOnly && !isLoggedIn)"
          [routerLink]="route.metaUrl"
          >{{ route.menuName }}</a
        >
      </ng-container>

      <ng-container *ngIf="!isLoggedIn">
        <a routerLink="login">Mijn V-NS</a>
      </ng-container>
    </nav>
  </div>
</header>
<mat-divider></mat-divider>

<main>
  <ca-breadcrumb></ca-breadcrumb>

  <!-- [@routeAnimations]="getRouteAnimationData()" -->
  <div class="page">
    <router-outlet></router-outlet>
  </div>
</main>

<footer>
  <div class="nav-container">
    <nav class="footer">
      <a *ngFor="let route of nav.footerMenuLinks" [routerLink]="route.metaUrl">{{
        route.menuName
      }}</a>
    </nav>
  </div>
</footer>
