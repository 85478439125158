import { Component, Input, OnInit, signal, ViewChild } from '@angular/core';
import { injectStripe, StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import {
  PaymentIntent,
  PaymentIntentResult,
  StripeElementsOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { environment } from '../../../../environments/environment';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { VATCheckService } from '@ca/ca-ng-core';
import { SnackbarService } from '@ca/ca-snackbar';
import { RegisterFormPlugin } from '@ca/vns-models';
import { debounceTime, firstValueFrom, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AnalyticsService } from '../../services/analytics.service';
import { HttpClient } from '@angular/common/http';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

// https://docs.stripe.com/payments/quickstart
@Component({
  selector: 'ca-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class RegistrationFormComponent implements OnInit {
  @Input() plugin!: RegisterFormPlugin;
  @ViewChild(MatStepper) stepper!: MatStepper;
  @ViewChild(StripePaymentElementComponent) paymentElement?: StripePaymentElementComponent;

  protected readonly stripe = injectStripe();

  protected paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      // radios: false,
      // spacedAccordionItems: false,
    },
  };

  protected elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: { theme: 'flat' },
  };

  personal_info_fg = this.fb.group({
    first_name: this.fb.control<string | null>(null, Validators.required),
    last_name: this.fb.control<string | null>(null, Validators.required),
    email: this.fb.control<string | null>(null, [Validators.required, Validators.email]),
    tel: this.fb.control<string | null>(null, [Validators.required]),
  });

  company_info_fg = this.fb.group({
    street: this.fb.control<string | null>(null, Validators.required),
    street_nr: this.fb.control<string | null>(null, Validators.required),
    street_suffix: this.fb.control<string | null>(null),
    postal_code: this.fb.control<string | null>(null, Validators.required),
    city: this.fb.control<string | null>(null, Validators.required),
    // company
    name: this.fb.control<string | null>(null, Validators.required),
    website: this.fb.control<string | null>(null),
    vat_number: this.fb.control<string | null>(null, Validators.required),
    iban: this.fb.control<string | null>(null),
    bic: this.fb.control<string | null>(null),
  });

  account_fg = this.fb.group(
    {
      password: this.fb.control<string | null>(null, Validators.required),
      password_confirmation: this.fb.control<string | null>(null, Validators.required),
      conditions: this.fb.control(false, Validators.requiredTrue),
      marketing_mails_consent: this.fb.control(false, Validators.required),
    },
    { validators: confirmPasswordValidator }
  );

  paying = signal(false);

  private paymentIntentResult?: PaymentIntent;
  private confirmPaymentResult?: PaymentIntentResult;

  constructor(
    private readonly fb: FormBuilder,
    private vies: VATCheckService,
    private store: Store,
    private snackbar: SnackbarService,
    private analytics: AnalyticsService,
    private stripeService: StripeService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const fc = this.company_info_fg.controls['vat_number'];

    if (fc)
      fc.valueChanges.pipe(debounceTime(300)).subscribe(async () => {
        const v = fc.value;
        if (typeof v === 'string' && v.length === 12) {
          const res = await firstValueFrom(this.vies.CheckVAT(v));
          if (res.valid && res) {
            // this.snackbar.infoSnackbar('Bedrijfsgegevens gevonden.');
            if (res.countryCode !== 'BE') {
              this.snackbar.errorSnackbar(
                'Enkel Belgische bedrijven kunnen zich inschrijven voor deze service'
              );
              return;
            }
            this.company_info_fg.patchValue({
              street: res.address.street,
              city: res.address.city,
              name: res.name,
              street_nr: res.address.number,
              postal_code: res.address.zip_code,
            });
          } else if (!res.valid)
            this.snackbar.errorSnackbar(
              'Kon geen bedrijfsgegevens vinden voor BTW-nummer: ' + fc.value
            );
        }
      });
  }

  // submit() {
  //   const dto = this.personal_info_fg.value as RegisterDTO;
  //   // console.log('submit form', dto);
  //   this.analytics.trackEvent('register', 'register form submitted by user', 'form submit');
  //   // this.store.dispatch(
  //   //   SubmitRegistrationForm({
  //   //     ...dto,
  //   //   })
  //   // );
  // }

  // elementsOptions: StripeElementsOptions = {
  //   locale: 'en',
  //   clientSecret: environment.stripePrivateKey,
  //   // client: '{{YOUR_CLIENT_SECRET}}',
  //   appearance: {
  //     theme: 'flat',
  //   },
  // };

  // paymentElementOptions: StripePaymentElementOptions = {
  //   layout: {
  //     type: 'tabs',
  //     defaultCollapsed: false,
  //     radios: false,
  //     spacedAccordionItems: false,
  //   },
  // };

  createPaymentIntent(): void {
    // TODO: send registerDTO to server to store in DB
    const name = this.company_info_fg.get('name')?.value;
    console.log('paying...', name);
    if (this.personal_info_fg.valid && name) {
      console.log('validated form, sending request...');
      firstValueFrom(this.createPaymentIntentCall(200)).then((pi) => {
        if (pi.client_secret) {
          this.elementsOptions.clientSecret = pi.client_secret;
          this.paymentIntentResult = pi;
          console.log('payment result', pi);
          this.stepper.next();
        } else this.snackbar.errorSnackbar('Kon geen betalingsdialoog starten...');
      });
    } else {
      console.log(this.personal_info_fg);
    }
  }

  confirmPayment() {
    if (this.paying() || this.company_info_fg.invalid || !this.paymentElement) return;
    this.paying.set(true);

    const { name, city, postal_code, street, street_nr, street_suffix } =
      this.company_info_fg.getRawValue();
    const { first_name, email, last_name, tel } = this.personal_info_fg.getRawValue();

    this.stripe
      .confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: name as string,
              email: email as string,
              phone: tel as string,
              address: {
                line1: street + ' ' + street_nr + (street_suffix ? ' ' + street_suffix : ''),
                postal_code,
                city: city as string,
              },
            },
          },
        },
        redirect: 'if_required',
      })
      .subscribe((result) => {
        this.paying.set(false);
        console.log('Result', result);
        this.confirmPaymentResult = result;
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          alert({ success: false, error: result.error.message });
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            // alert({ success: true });
            this.stepper.next();
          }
        }
      });
  }

  completeRegistration() {
    console.log('intent', this.paymentIntentResult);
    console.log('result', this.confirmPaymentResult);
    console.log(
      'forms',
      this.personal_info_fg.getRawValue(),
      this.company_info_fg.getRawValue(),
      this.account_fg.getRawValue()
    );
  }

  fetchUpdates() {
    this.paymentElement?.fetchUpdates();
  }

  update(options: Partial<StripePaymentElementOptions>) {
    this.paymentElement?.update(options);
  }

  // TODO: move to service
  createPaymentIntentCall(amount: number): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${environment.backendUrl}/api/registrations/create-payment-intent`,
      {
        amount,
      }
    );
  }
}
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value.password === control.value.password_confirmation
    ? null
    : { PasswordNoMatch: true };
};
