import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadMessageIntoDetail,
  LoadTicketDetail,
  LoadTicketDetailFailure,
  LoadTicketDetailSuccess,
  LoadTickets,
  LoadTicketsFailure,
  LoadTicketsSuccess,
  MessageReceived,
} from '../reducers/my-file.reducer';
import { firstValueFrom, map, switchMap } from 'rxjs';
import { TicketsService } from '../../services/tickets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { getPath } from '../../util';
import { QueueNotification } from '@ca/ca-ng-core';

@Injectable()
export class MyFileEffects {
  constructor(
    private readonly actions$: Actions,
    private tickets: TicketsService,
    private route: ActivatedRoute
  ) {}

  onLoadTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTickets),
      switchMap(() =>
        firstValueFrom(this.tickets.getTickets())
          .then((res) => LoadTicketsSuccess({ tickets: res }))
          .catch((err) => LoadTicketsFailure({ error: err }))
      )
    )
  );

  onLoadTicketDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTicketDetail),
      switchMap(({ id: metaUrl }) =>
        firstValueFrom(this.tickets.getTicketDetail(metaUrl))
          .then((res) => LoadTicketDetailSuccess({ ticket: res }))
          .catch((err) => LoadTicketDetailFailure({ error: err }))
      )
    )
  );

  onMessageReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageReceived),
      map(({ message }) => {
        const p = getPath();
        if (!message.ticket_id)
          return QueueNotification({
            notification: {
              lifetime: 10000,
              message: 'Kon bericht niet plaatsen, ticket niet bekend..',
              source: 'Websocket',
              type: 'error',
            },
          });

        return p.join() === ['mijn-dossier', message.ticket_id].join() // p.includes('mijn-dossier') && p[p.length - 1] != 'mijn-dossier'
          ? LoadMessageIntoDetail({ message })
          : QueueNotification({
              notification: {
                lifetime: 5000,
                message: `Je hebt een bericht ontvangen van ${message.sent_by_name}.`,
                source: 'Websocket',
                type: 'info',
                actions: [
                  {
                    text: 'Lezen',
                    routerLink: ['/mijn-dossier', message.ticket_id],
                  },
                ],
              },
            });
      })
    )
  );
}
