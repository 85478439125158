export class ServiceMethodNotAllowedError extends Error {
  constructor(method: 'GET' | 'POST' | 'PUT' | 'DELETE', resourceName: string) {
    super(`METHOD ${method} not allowed on service: ${resourceName}.`);
  }
}
export class EmptyBodyError extends Error {
  constructor() {
    super('A body was expected on the HTTP response, but it was empty.');
  }
  override name = 'Empty body error';
}
