import { Component, OnInit } from '@angular/core';
import { BreadcrumbSegment, BreadcrumbService } from '../../services/breadcrumb.service';
@Component({
  selector: 'ca-breadcrumb',
  template: `<nav *ngIf="breadcrumbs.length > 0" class="breadcrumb-nav">
    <a [routerLink]="['/home']"><mat-icon>home</mat-icon></a>
    <span> / </span>
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <ng-container *ngIf="!last">
        <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
        <span> / </span>
      </ng-container>
      <ng-container *ngIf="last">
        <span>{{ breadcrumb.label }} </span>
      </ng-container>
    </ng-container>
  </nav> `,
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Array<BreadcrumbSegment> = [];
  constructor(private svc: BreadcrumbService) {}

  ngOnInit(): void {
    this.svc.Breadcrumbs$.subscribe((breadcrumbs) => (this.breadcrumbs = breadcrumbs));
  }
}
