export const nameof = <T>(name: keyof T) => name;
export const nameofFactory =
  <T>() =>
  (name: keyof T) =>
    name;
export const getProperty = <Type, Key extends keyof Type>(
  obj: Type,
  key: Key
) => obj[key];
export type Getters<Type> = {
  [Property in keyof Type as `get${Capitalize<
    string & Property
  >}`]: () => Type[Property];
};
export function getPropertyName<T extends object>(
  obj: T,
  expression: (instance: T) => any
) {
  const res: any = {};
  Object.keys(obj).map((k) => {
    res[k] = () => k;
  });
  return expression(res)();
}
export const isString = <T = any>(str: string | T): str is string => {
  return typeof str === 'string';
};

export const getIdIfObject = (v: any): number => {
  return typeof v == 'number' ? v : v.id;
};

// Forms
export function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}
export function onlyUniqueIndexedNames(
  value: { id: number; name: string },
  index: number,
  self: { id: number; name: string }[]
) {
  return self.findIndex((e) => e.id === value.id) === index;
}
export function onlyUniqueTypeSafe<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}

export const isEmptyObject = (obj: any) => Object.keys(obj).length == 0;

/**
 * String Utils
 */

/**
 * Trims all leading and trailing whitespace from a string.
 * @param text the text to trim
 * @returns trimmed text
 */
export function trimLeadingAndTrailingSpaces(text: string) {
  let trimmed;
  if (typeof text == 'undefined')
    throw new TypeError('Text was undefined. No text, no trim...');
  else trimmed = text.replace(/(^\s+|\s+$)/g, '');
  return trimmed;
}

/**
 * Checks if a string can be safely converted to a number;
 * @param value string to check
 * @returns if the string can be safely converted to a number
 */
export function stringIsNumber(value: string): boolean {
  if (isNaN(+value) === false) {
    const v = +value;
    return typeof v === 'number';
  }
  return false;
}
/**
 * Turns any string into its camelcase representation.
 *
 * for example
 * ```
 * // all output "equipmentClassName"
 * console.log(camelize("EquipmentClass name"));
 * console.log(camelize("Equipment className"));
 * console.log(camelize("equipment class name"));
 * console.log(camelize("Equipment Class Name"));
 * ```
 * @param str the string to convert to camelcase
 * @returns
 */
export function camelize(str: string) {
  // if (!str) return str;
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function GroupBy<K, V>(list: V[], keyGetter: (e: V) => K) {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key: K = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function displayNamedObjectOrString<T extends NamedObject>(
  option?: T | string
) {
  return option ? (isString(option) ? option : option.name) : '';
}
export type NamedObject = { name: string };
export interface IndexedName {
  id: number;
  name: string;
}
export function filterByNamedObjectOrString<T extends NamedObject>(
  value: T | string,
  arr?: T[]
): T[] {
  const filterValue = (isString(value) ? value : value.name).toLowerCase();
  return arr
    ? arr.filter((option) =>
        option.name.toLocaleLowerCase().includes(filterValue)
      )
    : [];
}
