export interface IResetPasswordMessages {
  title: string;
  form_instructions: string;
  form: {
    email: string;
    back_button_text: string;
    submit_button_text: string;
    invalid: string;
  };
  link_sent: string;
  no_account_with_email: string;
}
