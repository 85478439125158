import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { removeUser } from '../../../store/actions';
import { IUserProfile, OnboardingState } from '../../../types';

@Component({
  selector: 'ca-remove-user-dialog',
  templateUrl: './remove-user-dialog.component.html',
  styleUrls: ['./remove-user-dialog.component.scss'],
})
export class RemoveUserDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUserProfile,
    private store: Store<{ onboarding: OnboardingState }>
  ) {}
  removeUser(id: number | string) {
    this.store.dispatch(removeUser({ id }));
  }
}
