import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarService } from './snackbar.service';
import { SnackbarModuleConfiguration, SnackbarServiceConfiguration } from './config';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from './store/effects/notification.effects';
import { ActionSnackbarComponent } from './components/action-snackbar/action-snackbar.component';
import { MatCommonModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatCommonModule,
    MatSnackBarModule,
    MatButtonModule,
    RouterModule,
    EffectsModule.forFeature([NotificationEffects]),
  ],
  providers: [SnackbarService],
  declarations: [ActionSnackbarComponent],
  exports: [ActionSnackbarComponent],
})
export class SnackbarModule {
  static forRoot(configuration: SnackbarModuleConfiguration): ModuleWithProviders<SnackbarModule> {
    return {
      ngModule: SnackbarModule,
      providers: [
        {
          provide: SnackbarModuleConfiguration,
          useValue: configuration,
        },
        {
          provide: SnackbarServiceConfiguration,
          useValue: configuration.service,
        },
      ],
    };
  }
}
