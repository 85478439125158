import { IAreYouSureOverlay } from './interfaces/IAreYouSureOverlay';

export class AreYouSureOverlayConfiguration {
  defaults!: Omit<IAreYouSureOverlay, 'close' | 'validate'>;
  constructor(defaults: Omit<IAreYouSureOverlay, 'close' | 'validate'>) {
    this.defaults = defaults;
  }
}
export const DEFAULT_ARE_YOU_SURE_OVERLAY_CONFIGURATION =
  new AreYouSureOverlayConfiguration({
    cancelButtonText: 'NO',
    confirmButtonText: 'YES',
    confirmQuestion: 'Are you sure you want to delete this resource?',
    title: 'Delete confirmation',
  });
