import { ContactFormDTO, ContactFormPlugin, RegisterDTO, RegisterResultDTO } from '@ca/vns-models';
import { createAction, props } from '@ngrx/store';

export enum FormsActions {
  submitRegistrationForm = '[Registration Form] Submit',
  submitRegistrationFormSuccess = '[Registration Form] Submitted successfully',
  submitRegistrationFormFailure = '[Registration Form] Submit failed',

  submitContactForm = '[Contact Form] Submit',
  submitContactFormSuccess = '[Contact Form] Submitted successfully',
  submitContactFormFailure = '[Contact Form] Submit failed',
}

export const SubmitRegistrationForm = createAction(
  FormsActions.submitRegistrationForm,
  props<RegisterDTO>()
);
export const SubmitRegistrationFormSuccess = createAction(
  FormsActions.submitRegistrationFormSuccess,
  props<{ req: RegisterDTO; res: RegisterResultDTO }>()
);
export const SubmitRegistrationFormFailure = createAction(
  FormsActions.submitRegistrationFormSuccess,
  props<{ req: RegisterDTO; error: any }>()
);

// export const CreatePaymentIntent = createAction(
//   FormsActions.createPaymentIntent,
//   props<{ req: }>
// )

export const SubmitContactForm = createAction(
  FormsActions.submitContactForm,
  props<{ value: ContactFormDTO; form: ContactFormPlugin }>()
);
export const SubmitContactFormSuccess = createAction(
  FormsActions.submitContactFormSuccess, // TODO: response interface
  props<{ value: ContactFormDTO; form: ContactFormPlugin; res: boolean }>()
);
export const SubmitContactFormFailure = createAction(
  FormsActions.submitContactFormFailure,
  props<{ req: RegisterDTO; error: any }>()
);
