import { ActionReducerMap } from '@ngrx/store';

import * as fromNotifications from './notifications';

export interface CoreState {
  notifications: fromNotifications.NotificationsState;
}

export const ROOT_REDUCER: ActionReducerMap<CoreState> = {
  notifications: fromNotifications.reducer,
};
