export interface CMSBase {
  success: boolean;
  type: string;
  debug: boolean;
}
/**
 * Page Routing Info
 */
export interface PageBaseInfo {
  id: number;
  metaUrl: string;
  membersOnly: boolean;
  guestOnly: boolean;
  metaTitle: string;
}
export type PageRouteInfo = PageBaseInfo & {
  type: 'route';
  routeParam: boolean;
};

export type PageSubRouteInfo = Omit<PageBaseInfo, 'membersOnly' | 'guestOnly'> & {
  type: 'sub-route';
  parentMetaUrl: string;
  seo: {
    description: string;
  };
};

/**
 * Page Link Info
 */
export type PageLinkInfo = PageBaseInfo & {
  type: 'link';
  menuName: string;
  location: 'header:primary' | 'header:secondary' | 'footer' | 'dropdown';
  routeParam: boolean;
};

export type PageInfo = PageRouteInfo | PageLinkInfo;

export interface CMSGeneral extends CMSBase {
  pages: PageInfo[];
}

//#region Plugins
export interface PluginBase {
  pluginTitle?: string;
  pluginId: string;
  text?: string;
}

/* Navigation List */
export type NavListPlugin = PluginBase & {
  type: 'cta-links';
  listType: 'none' | 'bullet' | 'number';
  navItems: CTA[];
};

/* Accordion */
export type MediaFile = {
  id: number;
  title: string;
  downloadUrl: string;
};
export type AccordeonItem = {
  id: number;
  title: string;
  text: string;
  img?: Img;
  mediaFiles?: MediaFile[];
  cta?: CTA;
};
export type AccordeonPlugin = PluginBase & {
  type: 'accordeon';
  accordionItems: AccordeonItem[];
};

/* Checked List */
export type CheckedListPlugin = PluginBase & {
  type: 'checked-list';
  checkedListItems: string[];
};

/* Partners */
export interface PartnerItem {
  id: string;
  name: string;
  logo: string; // alt uitschrijven als "logo [name]"
  href: string;
}
export type PartnersPlugin = PluginBase & {
  type: 'partners';
  partnersList: PartnerItem[];
  appearance: 'minimal' | 'list';
  cta: CTA;
};

/* Registratie formulier */
export type FormPluginBase = PluginBase & {
  successMessage: string;
  errorMessage: string;
};
export type RegisterFormPlugin = FormPluginBase & {
  type: 'register-form';
  company_info_title: string;
  personal_info_title: string;
  account_info_title: string;
};

export type ContactFormPlugin = FormPluginBase & {
  type: 'contact-form';
};

export type TextBlock = PluginBase & {
  type: 'text-block';
  text: string;
  cta?: CTA;
};

export interface Img {
  alt: string;
  src: string;
}
export interface CTA {
  title: string;
  link: { path: string; fragment?: string; queryParams?: { [key: string]: string } }; // zie NavigationExtras
}

export type CardPlugin = PluginBase & {
  type: 'card';
  text?: string;
  img: Img;
  cta: CTA;
};

export interface Tag {
  id: number;
  name: string;
}

export interface TagsGroup {
  id: number;
  name: string;
  tags: Tag[];
}

export interface NewsArticle extends PageSubRouteInfo {
  title: string;
  short_text: string; // voor card in gallery
  text: string; // html
  img: Img;
  tag_groups: TagsGroup[];
}

export type NewsGalleryPlugin = PluginBase & {
  type: 'news-gallery';
  articles: NewsArticle[];
};

export type LatestNewsPlugin = PluginBase & {
  type: 'latest-news';
  articles: NewsArticle[];
  cta?: CTA;
};
export type StreetAddress = {
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
};

export type ContactInfoPlugin = PluginBase & {
  type: 'contact-info';
  phoneNumber: string;
  companyNumber: string;
  streetAddress: StreetAddress;
};

export type Plugin =
  | NavListPlugin
  | AccordeonPlugin
  | CheckedListPlugin
  | PartnersPlugin
  | TextBlock
  | CardPlugin
  | ContactFormPlugin
  | RegisterFormPlugin
  | NewsGalleryPlugin
  | LatestNewsPlugin
  | ContactInfoPlugin;
//#endregion

/**
 * Pages
 */
export interface Page {
  id: number;
  title: string;
  text: string; // html

  seo: {
    meta_description: string;
    // ander meta data?
  };

  plugins: Plugin[];
}
