import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from '../types';
import { OnboardingService } from './onboarding.service';
import { CaEnvironment } from '@ca/ca-utils';
import { CA_ENVIRONMENT, LoggingService } from '@ca/ca-ng-core';
import { UnauthorizedPageLoad } from '../store/actions/utility.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private onboarding: OnboardingService,
    private logger: LoggingService,
    private store: Store<State>,
    @Inject(CA_ENVIRONMENT) private env: CaEnvironment
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!sessionStorage.getItem(this.env.sessionStorageBearerKey)) {
      this.logger.log('not authorized');
      this.store.dispatch(
        UnauthorizedPageLoad({
          url: state.url,
        })
      );
      return false;
    } else {
      return this.onboarding
        .checkBearerToken()
        .then((res) => {
          if (!res) {
            this.store.dispatch(
              UnauthorizedPageLoad({
                url: state.url,
              })
            );
            return false;
          }
          this.logger.log('authorized');
          return true;
        })
        .catch(() => {
          this.logger.log('not authorized');
          this.store.dispatch(
            UnauthorizedPageLoad({
              url: state.url,
            })
          );
          return false;
        });
    }
  }
}
