import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/reducers/app.reducer';
import { PagesState } from '../../store/reducers/pages.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { Page } from '@ca/vns-models';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { getPath } from '../../util';

@Component({
  selector: 'ca-public-page',
  templateUrl: './public-page.component.html',
})
export class PublicPageComponent implements OnInit, OnDestroy {
  private sub = new CaSubscriber();
  protected pageData?: Page;
  protected activeFragment: string | null = null;
  resourceUrlBase = environment.baseUrl;

  constructor(
    private store: Store<{ app: AppState; pages: PagesState }>,
    private meta: Meta,
    protected route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const path = getPath();

    this.sub.subscribe(this.route.fragment, {
      next: (fragment: string | null) => {
        if (fragment !== null) console.log('fragment detected');
        this.activeFragment = fragment;
        if (fragment === null)
          scrollTo({
            top: 0,
          });
      },
    });

    this.sub.subscribe(
      this.store.select((s) => s.pages[path[0]]),
      {
        next: (pageData?: Page) => {
          this.pageData = pageData;
          this.updateSEO(pageData);
        },
      }
    );
  }

  private updateSEO(page?: Page) {
    //#region SEO
    if (page && page.seo.meta_description !== '') {
      // set description
      this.meta.updateTag({ name: 'description', content: page.seo.meta_description });
    } else {
      // TODO: set home description
      this.meta.removeTag('name=description'); // was this.meta.updateTag({ name: 'description', content: 'HOME' });
    }
    //#endregion
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
