/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ApiGetResult } from '../interfaces/ApiGetResult';
import { PostResult } from '../interfaces/PostResult';
import { PutResult } from '../interfaces/v2';
import { IResourceService } from '../interfaces/IResourceService';
import { EmptyBodyError, ServiceMethodNotAllowedError } from '../models/errors';
import { ResourceServiceConfig } from '../ResourceServiceConfig';
import { UpsertType } from '../types/UpsertType';

import { CaDataService } from './ca-data.service';
import { Observable, firstValueFrom } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { CaEnvironment, IQueryParameter, DatabaseModel } from '@ca/ca-utils';
import { CA_ENVIRONMENT, LoggingService } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export abstract class CaResourceService<Resource extends DatabaseModel> implements IResourceService<Resource> {
  // abstract name: string;
  abstract config: ResourceServiceConfig;
  constructor(@Inject(CA_ENVIRONMENT) protected env: CaEnvironment, protected logger: LoggingService, protected data: CaDataService) {}

  get route() {
    return `${this.env.apiBaseUrl}${this.config.route}`;
  }

  get(queryParams?: IQueryParameter[]): Promise<Resource[]> {
    if (this.config.allowGet) {
      return firstValueFrom(this.data.get<ApiGetResult<Resource>>(this.route, queryParams))
        .then((res: HttpResponse<ApiGetResult<Resource>>) => {
          if (!res.body) throw new EmptyBodyError();
          const list: Resource[] = res.body.list;
          if (!res.body.list) {
            this.logger.logError('response for ' + this.route + ' did not include list');
            this.logger.logError(res);
            return [];
          }
          return list;
        })
        .catch((err) => {
          this.logger.logError('ERROR', err);
          throw new Error('Could not load data');
        });
    } else {
      throw new ServiceMethodNotAllowedError('GET', this.config.resourceName);
    }
  }

  getObs(queryParams?: IQueryParameter[]): Observable<Resource[]> {
    if (this.config.allowGet) {
      return this.data
        .get<ApiGetResult<Resource>>(this.route, queryParams)
        .pipe(share())
        .pipe(
          map((res: HttpResponse<ApiGetResult<Resource>>) => {
            if (!res.body) throw new EmptyBodyError();
            const list: Resource[] = res.body.list;
            if (!res.body.list) {
              this.logger.logError('response for ' + this.route + ' did not include list');
              this.logger.logError(res);
              return [];
            }
            return list;
          })
        );
    } else {
      throw new ServiceMethodNotAllowedError('GET', this.config.resourceName);
    }
  }

  getWithCount(queryParams?: IQueryParameter[]): Promise<{ data: Resource[]; count: number }> {
    if (this.config.allowGet) {
      return firstValueFrom(this.data.get<ApiGetResult<Resource>>(this.route, queryParams))
        .then((res: HttpResponse<ApiGetResult<Resource>>) => {
          if (!res.body) throw new EmptyBodyError();
          const list: Resource[] = res.body.list;
          if (!res.body.list) {
            this.logger.logError('response for ' + this.route + ' did not include list');
            this.logger.logError(res);
            return { data: [], count: 0 };
          }
          return { data: list, count: res.body.records };
        })
        .catch((err) => {
          this.logger.logError('ERROR', err);
          throw new Error('Could not load data');
        });
    } else {
      throw new ServiceMethodNotAllowedError('GET', this.config.resourceName);
    }
  }

  getObsWithCount(queryParams?: IQueryParameter[]): Observable<{ data: Resource[]; count: number }> {
    if (this.config.allowGet) {
      return this.data
        .get<ApiGetResult<Resource>>(this.route, queryParams)
        .pipe(share())
        .pipe(
          map((res: HttpResponse<ApiGetResult<Resource>>) => {
            if (!res.body) throw new EmptyBodyError();
            const list: Resource[] = res.body.list;
            if (!res.body.list) {
              this.logger.logError('response for ' + this.route + ' did not include list');
              this.logger.logError(res);
              return { data: [], count: 0 };
            }
            return { data: list, count: res.body.records };
          })
        );
    } else {
      throw new ServiceMethodNotAllowedError('GET', this.config.resourceName);
    }
  }

  getSingle(id: number, params?: IQueryParameter[]): Promise<Resource> {
    if (this.config.allowGet) {
      if (this.config.isWrappedGetResponse)
        return firstValueFrom(this.data.get<ApiGetResult<Resource>>(this.route, [...(params ?? []), { name: this.config.idName, value: id }])).then((res: HttpResponse<ApiGetResult<Resource>>) => {
          if (!res.body) throw new EmptyBodyError();
          const resource = res.body.list.pop();
          if (!resource) {
            this.logger.logError('response for ' + this.route + ' did not include list');
            this.logger.logError(res);
            throw new Error('Could not find resource with ID: ' + id);
          }
          return resource as Resource;
        });
      else
        return firstValueFrom(this.data.get<Resource>(this.route, [{ name: this.config.idName, value: id }])).then((res: HttpResponse<Resource>) => {
          if (!res.body) throw new EmptyBodyError();
          const resource = res.body;
          if (!resource) {
            this.logger.logError('response for ' + this.route + ' did not include list');
            this.logger.logError(res);
          }
          return resource as Resource;
        });
    } else {
      throw new ServiceMethodNotAllowedError('GET', this.config.resourceName);
    }
  }

  post(postData: UpsertType<Resource>): Promise<PostResult> {
    if (this.config.allowPost)
      return firstValueFrom(this.data.post(this.route, postData)).then((res: HttpResponse<PostResult>) => {
        if (!res.body) throw new EmptyBodyError();
        return res.body;
      });
    else throw new ServiceMethodNotAllowedError('POST', this.config.resourceName);
  }

  delete(id: number): Promise<boolean> {
    if (this.config.allowDelete && this.config.requireSafeDelete) return this.data.deleteSafely(this.route, { id: id });
    if (this.config.allowDelete && !this.config.requireSafeDelete) return this.data.deleteMultiple(this.route, [{ id: id }]);
    else throw new ServiceMethodNotAllowedError('DELETE', this.config.resourceName);
  }

  deleteMany(ids: number[]): Promise<boolean> {
    if (this.config.allowDelete)
      return this.data.deleteMultiple(
        this.route,
        ids.map((e) => {
          return { id: e };
        })
      );
    else throw new ServiceMethodNotAllowedError('DELETE', this.config.resourceName);
  }

  put<PutResultType = string>(putData: UpsertType<Resource>): Promise<PutResult<PutResultType>> {
    if (this.config.allowPut)
      return firstValueFrom(this.data.put<UpsertType<Resource>, PutResultType>(this.route, putData)).then((res: HttpResponse<PutResult<PutResultType>>) => {
        if (!res.body) throw new EmptyBodyError();
        return res.body;
      });
    else throw new ServiceMethodNotAllowedError('PUT', this.config.resourceName);
  }
}
