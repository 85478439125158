import { Component, Input } from '@angular/core';
import { CTA } from '@ca/vns-models';

@Component({
  selector: 'ca-cta',
  template: `<a
    *ngIf="plugin.cta"
    class="cta-btn"
    [routerLink]="[plugin.cta.link.path]"
    [fragment]="plugin.cta.link.fragment"
    >{{ plugin.cta.title }}</a
  >`,
})
export class CtaComponent {
  @Input() plugin!: CtaContainer;
  @Input() listItem?: boolean;
}
export type CtaContainer =
  | {
      cta?: CTA;
    }
  | {
      cta: CTA;
    };
