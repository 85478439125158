import { createAction, props } from '@ngrx/store';
import { OnboardingAction } from '../config';

export const UnauthorizedPageLoad = createAction(
  OnboardingAction.UNAUTHORIZED_PAGELOAD,
  props<{
    url: string;
  }>()
);
export const UnauthorizedHttpRequest = createAction(
  OnboardingAction.UNAUTHORIZED_HTTP_REQUEST,
  props<{
    url: string;
    error: any;
  }>()
);
