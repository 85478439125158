<mat-card class="personal-info-card">
  <mat-card-header>
    <div
      *ngIf="profile.avatar && profile.avatar.length > 0"
      mat-card-avatar
      [style]="
        'background-image: url(' + profile.avatar + '); background-size: cover;'
      "
    ></div>
    <mat-card-title>
      {{ profile.firstName }} {{ profile.lastName }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ca-user-summary-grid [profile]="profile"></ca-user-summary-grid>
  </mat-card-content>
  <ng-content></ng-content>
</mat-card>
