import { IOnboardingRoutes } from '../types/interfaces/configuration';

export const ONBOARDING_APP_ROUTES = {
  REGISTER: 'register',
  LOGIN: 'login',
  RESETPASS: 'reset-password',
  ACCESS: 'access',
  PROFILE: 'edit-profile',
  REDIRECT: 'dashboard',
  USER_MANAGEMENT: 'user-management'
};

export const API_ROUTES: IOnboardingRoutes = {
  register: `/api/user/new-user-record`,
  login: `/api/user/login`,
  logout: `/api/user/logout`,
  check2FA: `/api/user/2fa-check`,
  checkToken: `/api/user/check`,
  heartbeat: `/api/user/heartbeat`,
  setPassword: `/api/user/set-password`,
  resetPassword: `/api/user/send-token`,
  profile: '/api/user/profile',
  updateProfile: '/api/user/update-profile',
  postAvatar: '/api/user/avatar',
  toggle2FA: '/api/user/2fa',
  users: '/api/user/users',
  divisions: '/api/user/divisions',
  roles: '/api/user/roles',
};
