<div class="main">
  <ng-container *ngIf="pageData">
    <h1 *ngIf="pageData.title.length > 0">{{ pageData.title }}</h1>
    <div *ngIf="pageData.text.length > 0" [innerHTML]="pageData.text"></div>

    <div *ngFor="let plugin of pageData.plugins" [id]="plugin.pluginId" [ngClass]="plugin.type">
      <!-- TODO: @Mitch Braem Template voor plug-in in ng-container zetten -->
      <ng-container *ngIf="plugin.type === 'cta-links'">
        <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
        <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>
        <mat-nav-list class="cta-links">
          <mat-list-item
            *ngFor="let cta of plugin.navItems"
            mat-list-item
            [routerLink]="[cta.link.path]"
            [fragment]="cta.link.fragment"
            [queryParams]="cta.link.queryParams">
            <span *ngIf="plugin.listType === 'number'"></span>
            <span *ngIf="plugin.listType === 'bullet'"></span>
            {{ cta.title }}
          </mat-list-item>
        </mat-nav-list>
      </ng-container>

      <ng-container *ngIf="plugin.type === 'register-form'">
        <ca-registration-form [plugin]="plugin"></ca-registration-form>
      </ng-container>

      <ng-container *ngIf="plugin.type === 'accordeon'">
        <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
        <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>
        <ca-accordeon-plugin
          [plugin]="plugin"
          [activeFragment$]="this.route.fragment"></ca-accordeon-plugin>
        <!-- TODO: move to its own component & use @ViewChild om te expanden op fragment -->
      </ng-container>

      <ng-container *ngIf="plugin.type === 'checked-list'">
        <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
        <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>

        <mat-list>
          <mat-list-item *ngFor="let listItem of plugin.checkedListItems">
            <mat-icon matListItemIcon>checked</mat-icon>
            {{ listItem }}
          </mat-list-item>
        </mat-list>
      </ng-container>

      <ng-container *ngIf="plugin.type === 'partners'">
        <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
        <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>

        <mat-nav-list *ngIf="plugin.appearance === 'list'" class="partners-list">
          <a mat-list-item *ngFor="let partner of plugin.partnersList" [href]="partner.href">
            <img
              matListItemIcon
              class="partner-icon"
              [src]="resourceUrlBase + partner.logo"
              [alt]="partner.name + ' logo'" />
            <span matListItemTitle>
              {{ partner.name }} <mat-icon fontIcon="arrow_right_alt" inline></mat-icon>
            </span>
          </a>
        </mat-nav-list>
        <div *ngIf="plugin.appearance === 'minimal'" class="home-list-partners">
          <div *ngFor="let partner of plugin.partnersList">
            <a [href]="partner.href">
              <img [src]="resourceUrlBase + partner.logo" [alt]="partner.name + ' logo'" />
            </a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="plugin.type === 'text-block'">
        <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
        <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>
        <ca-cta [plugin]="plugin"></ca-cta>
      </ng-container>

      <!-- Card -->
      <ng-container *ngIf="plugin.type === 'card'">
        <div class="card hero">
          <!-- <img *ngIf="plugin.img" [src]="resourceUrlBase + plugin.img.src" [alt]="plugin.img.alt" />-->
          <div class="hero-content">
            <h1 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h1>
            <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>
            <ca-cta [plugin]="plugin"></ca-cta>
          </div>
        </div>
      </ng-container>

      <!-- News Gallery -->
      <ng-container *ngIf="plugin.type === 'news-gallery'">
        <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
        <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>
        <div class="news-article-list">
          <ca-news-gallery [newsPlugin]="plugin"></ca-news-gallery>
        </div>
      </ng-container>

      <!-- Latest News -->
      <ng-container *ngIf="plugin.type === 'latest-news'">
        <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
        <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>
        <div class="news-article-list">
          <ca-news-article-summary
            *ngFor="let article of plugin.articles"
            [article]="article"></ca-news-article-summary>
        </div>
      </ng-container>

      <!-- Contact info -->
      <ng-container *ngIf="plugin.type === 'contact-info'">
        <h2 *ngIf="plugin.pluginTitle">{{ plugin.pluginTitle }}</h2>
        <div *ngIf="plugin.text" [innerHTML]="plugin.text"></div>

        <p class="address">
          <mat-icon aria-hidden="false" aria-label=" home icon" fontIcon="home"></mat-icon>
          {{ plugin.streetAddress.street }}
          {{ plugin.streetAddress.streetNumber }}, {{ plugin.streetAddress.postalCode }}
          {{ plugin.streetAddress.city }}
        </p>
        <p>
          <mat-icon aria-hidden="false" aria-label=" phone icon" fontIcon="storefront"></mat-icon
          >{{ plugin.companyNumber }}
        </p>

        <p>
          <mat-icon aria-hidden="false" aria-label=" phone icon" fontIcon="phone"></mat-icon
          >{{ plugin.phoneNumber }}
        </p>
      </ng-container>

      <!-- Contact Form -->
      <ng-container *ngIf="plugin.type === 'contact-form'">
        <ca-contact-form [plugin]="plugin"></ca-contact-form>
      </ng-container>
    </div>
  </ng-container>
</div>
