import { ActionReducer, ActionReducerMap, INIT, MetaReducer } from '@ngrx/store';
import * as fromUser from './user.reducer';

export interface VNSAuthState {
  user: fromUser.UserState;
}

export const ROOT_REDUCER: ActionReducerMap<VNSAuthState> = {
  user: fromUser.reducer,
};

const log_prefix = '< Store >\n$';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...args: any): void => console.log(`${log_prefix}`, ...args);

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    log('state', state);
    log('action', action);
    return reducer(state, action);
  };
}

export const hydrationMetaReducer = (
  reducer: ActionReducer<VNSAuthState>
): ActionReducer<VNSAuthState> => {
  return (state, action) => {
    if (action.type === INIT) {
      console.log('restoring state from storage...');
      const storageValue = localStorage.getItem('state');
      if (storageValue !== null) {
        try {
          return JSON.parse(storageValue);
        } catch (err) {
          localStorage.removeItem('state');
        }
      }
    }
    const nextState = reducer(state, action);
    localStorage.setItem('state', JSON.stringify(nextState));
    return nextState;
  };
};

export const metaReducers: MetaReducer<VNSAuthState>[] = [hydrationMetaReducer];

export const FEATURE_KEY = 'vns-core';
