import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { OnboardingState } from '../../types/interfaces/store/OnboardingState';
import * as fromLogin from './login.reducer';
import * as fromUser from './user.reducer';

export const ROOT_REDUCER: ActionReducerMap<OnboardingState> = {
  login: fromLogin.reducer,
  user: fromUser.reducer,
};
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    log('state', state);
    log('action', action);
    return reducer(state, action);
  };
}
const log_prefix = '< Onboarding Store >\n$';
const log = (...args: any): void => console.log(`${log_prefix}`, ...args);

export const metaReducers: MetaReducer<OnboardingState>[] = [];
