import { OnboardingError, OnboardingErrorCode } from '../types';

export const CreateOnboardingError = (
  code: OnboardingErrorCode,
  message = 'Something went wrong'
): OnboardingError => ({
  code: code,
  message: message,
  error_class: 1,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOnboardingError = (item: any): item is OnboardingError =>
  item.error_class === 1;
