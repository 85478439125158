import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CaSubscriber } from '@ca/ca-utils';

import { io, Socket } from 'socket.io-client';
import { UserState } from './store/user.reducer';
import { Store } from '@ngrx/store';
import { FEATURE_KEY, VNS_ENVIRONMENT } from './config';
import { Message, SendMessageWsEvent, VNSEnvironment } from '@ca/vns-models';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SocketsService implements OnDestroy {
  private socket?: Socket;
  private token?: string;
  private sub = new CaSubscriber();

  constructor(
    private store: Store<{ [FEATURE_KEY]: { user: UserState } }>,
    @Inject(VNS_ENVIRONMENT) private env: VNSEnvironment
  ) {
    this.sub.subscribe(
      this.store.select((s) => s[FEATURE_KEY].user),
      {
        next: (user: UserState) => {
          this.token = user.jwt;
          if (user.isLoggedIn && user.jwt && user.user) {
            this.connect();
            this.sub.subscribe(this.getMessages(), {
              next: (messages) => {
                console.log('next', messages);
              },
            });
          } else {
            this.disconnect();
          }
        },
      }
    );
  }

  get isConnected() {
    return this.socket?.connected ?? false;
  }

  connect() {
    if (!this.isConnected) {
      if (!this.token) throw new Error('Can not connect with unauthorized user.');

      this.socket = io(this.env.backendUrl, {
        extraHeaders: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      this.socket.on('connect', () => {
        console.log('connected');
      });

      this.socket.on('disconnect', () => {
        console.log('disconnected');
      });
      this.socket.on('reconnect', (attempt) => {
        console.log('reconnected after ' + attempt + 'attempts');
      });

      this.socket.on('error', (e) => {
        console.error('socket error', e);
      });
    }
  }

  disconnect() {
    this.socket?.disconnect();
  }

  ngOnDestroy(): void {
    this.socket?.disconnect();
  }

  //#region Messages
  sendMessage(message: SendMessageWsEvent) {
    console.log('emitting from backoffice', message);
    this.socket?.emit('new-message', message);
  }

  getMessages() {
    const s = this.socket;
    if (!s) throw new Error('No socket defined');

    const observable = new Observable<Message>((observer) => {
      s.on('new-message', (data) => {
        observer.next(data);
      });
      return () => {
        console.log('disconnecting');
        s.disconnect();
      };
    });
    return observable;
  }
  //#endregion
}
