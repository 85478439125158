export interface IOnboardingRoutes {
  // TODO: expand to include all user routes (avatar etc.)
  register: string;
  login: string;
  logout: string;
  check2FA: string;
  checkToken: string;
  heartbeat: string;
  setPassword: string;
  resetPassword: string;
  profile: string;
  updateProfile: string;
  postAvatar: string;
  toggle2FA: string;
  users: string;
  divisions: string;
  roles: string;
}
