import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OnboardingForm } from './OnboardingForm';

export class TwoFactorForm extends OnboardingForm {
  get data(): FormData {
    const formData = new FormData();
    formData.append('code', this.formGroup.controls['authenticatorCode'].value);
    return formData;
  }

  markAsTouched() {
    this.formGroup.markAllAsTouched();
  }
  constructor(fb: FormBuilder) {
    super();
    this.formGroup = fb.group({
      authenticatorCode: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    });
  }
}
