import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PartialObserver } from 'rxjs';
import { resetPasswordRequest } from '../../store/actions';
import { OnboardingAction } from '../../store/config';
import {
  IResetPasswordMessages,
  ResetPasswordForm,
  IResetPasswordApiResponse,
  OnboardingModuleConfiguration,
  OnboardingState,
  ResetPasswordResponse,
  OnboardingError,
} from '../../types';
import { SnackbarService } from '@ca/ca-snackbar';
import { CaSubscriber } from '@ca/ca-utils';

@Component({
  selector: 'ca-reset-password',
  template: `
    <div
      class="login-form-container reset-password-container onboarding-container"
    >
      <div class="content">
        <div class="header">
          <h1 class="form-title">{{ msgs.title }}</h1>
          <p class="form-instructions">
            {{ msgs.form_instructions }}
          </p>
        </div>

        <form
          class="onboarding-form reset-password-form"
          [formGroup]="resetPasswordForm.formGroup"
          (ngSubmit)="onSubmit()"
        >
          <mat-form-field>
            <mat-label>{{ msgs.form.email }}</mat-label>
            <input matInput type="e-mail" formControlName="email" />
          </mat-form-field>

          <div class="buttons">
            <button
              mat-button
              (click)="goBack($event)"
              class="warning go-back-button"
            >
              {{ msgs.form.back_button_text }}
            </button>
            <button mat-button type="submit" class="success">
              {{ msgs.form.submit_button_text }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="loginPicture"></div>
  `,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  sub: CaSubscriber = new CaSubscriber();
  readonly msgs: IResetPasswordMessages;

  resetPasswordForm!: ResetPasswordForm;

  private resetPasswordObserver: PartialObserver<IResetPasswordApiResponse> = {
    next: (result: ResetPasswordResponse | OnboardingError) => {
      if (result && (result as { success: boolean }).success)
        this.resetPasswordForm.reset();
    },
  };

  constructor(
    private config: OnboardingModuleConfiguration,
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private router: Router,
    private store: Store<{ onboarding: OnboardingState }>,
    private actions$: Actions
  ) {
    this.msgs = this.config.messages.resetPassword;
    this.resetPasswordForm = new ResetPasswordForm(this.fb);
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  ngOnInit(): void {
    this.sub.subscribe(
      this.actions$.pipe(
        ofType(
          OnboardingAction.RESET_PASSWORD_FAILURE,
          OnboardingAction.RESET_PASSWORD_SUCCESS
        )
      ),
      this.resetPasswordObserver
    );
  }

  onSubmit(): void {
    if (this.resetPasswordForm.valid) {
      this.store.dispatch(
        resetPasswordRequest({
          data: this.resetPasswordForm.data,
        })
      );
    } else {
      this.snackbar.errorSnackbar(this.msgs.form.invalid);
    }
  }

  goBack(event?: Event): void {
    if (event) event.preventDefault();
    this.router.navigate([this.config.appRoutes.login]);
  }
}
