import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, firstValueFrom, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { CaDataModuleConfiguration } from '../CaDataModuleConfiguration';
import { HTTP_ERROR_CODE } from '../enums/HTTP_ERROR_CODE';
import { PostResult } from '../interfaces/PostResult';
import { PutResult, DeleteResult } from '../interfaces/v2';
import { DatabaseModel } from '@ca/ca-utils';
import { SnackbarService } from '@ca/ca-snackbar';
import {
  // CA_ENVIRONMENT,
  CaEnvironment,
  CaSubscriber,
  IQueryParameter,
  // QueryParametersService,
} from '@ca/ca-utils';
import {
  AreYouSureDefaultComponent,
  DEFAULT_ARE_YOU_SURE_OVERLAY_CONFIGURATION,
} from '@ca/ca-are-you-sure';
import {
  CA_ENVIRONMENT,
  LoggingService,
  QueryParametersService,
} from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class CaDataService implements OnDestroy {
  sub: CaSubscriber = new CaSubscriber();
  areYouSureDialogRef?: MatDialogRef<AreYouSureDefaultComponent>;
  public unauthorizedEvent: EventEmitter<void> = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    @Inject(CA_ENVIRONMENT) private env: CaEnvironment,
    private config: CaDataModuleConfiguration,
    private logger: LoggingService,
    private snackbar: SnackbarService,
    private http: HttpClient,
    private queryParameters: QueryParametersService
  ) {}

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  get<T = any>(
    route: string,
    params?: IQueryParameter[]
  ): Observable<HttpResponse<T>> {
    params = params ?? [];
    const url =
      params != undefined && params.length > 0
        ? this.queryParameters.addQueryParameters(route, params)
        : route;
    return this.http
      .get<T>(url, { headers: this.getAuthHeader(), observe: 'response' })
      .pipe(share())
      .pipe((e: Observable<HttpResponse<T>>) => this.errorPipe(e));
  }

  put<T, PutType>(
    route: string,
    putdata: T,
    params: IQueryParameter[] = []
  ): Observable<HttpResponse<PutResult<PutType>>> {
    return this.http
      .put<PutResult<PutType>>(
        params != undefined && params.length > 0
          ? this.queryParameters.addQueryParameters(route, params)
          : route,
        putdata,
        {
          headers: this.getAuthHeader(),
          observe: 'response',
        }
      )
      .pipe(share())
      .pipe((e) => this.errorPipe(e));
  }

  post<T, ResponseType = PostResult>(
    route: string,
    postData: T,
    params: IQueryParameter[] = []
  ): Observable<HttpResponse<ResponseType>> {
    if (!postData) throw new Error('Data is required for POST METHOD');

    return this.http
      .post<ResponseType>(
        params != undefined && params.length > 0
          ? this.queryParameters.addQueryParameters(route, params)
          : route,
        postData,
        {
          headers: this.getAuthHeader(),
          observe: 'response',
        }
      )
      .pipe(share())
      .pipe((e) => this.errorPipe(e));
  }

  genericDeleteSafely<DeleteModel>(route: string, body: DeleteModel) {
    this.areYouSureDialogRef = this.dialog.open(AreYouSureDefaultComponent, {
      disableClose: true,
    });
    return firstValueFrom(this.areYouSureDialogRef.afterClosed())
      .then(async (confirmed: boolean) => {
        if (!confirmed) return false;
        const deleted =
          confirmed &&
          (await firstValueFrom(this.delete(route, body))
            .then((res) => {
              const result = res.body?.success != undefined && res.body.success;
              if (result) this.snackbar.successSnackbar('Deleted.');
              else this.snackbar.errorSnackbar('Could not delete resource.');
              return result;
            })
            .catch((err) => {
              this.logger.logError('Delete error occured', err);
              return false;
            }));
        return deleted;
      })
      .catch(() => {
        this.logger.logError('Error occured in are you sure dialog.');
        return false;
      });
  }

  deleteSafely<T extends DatabaseModel>(
    route: string,
    data: T,
    params: IQueryParameter[] = []
  ): Promise<boolean> {
    if (!(data as any).id || (data as any).id == 0) {
      this.logger.logError('Cannot delete without id');
      return firstValueFrom(of(false));
    } else {
      // open a new modal and only delete if user chooses to confirm
      this.areYouSureDialogRef = this.dialog.open(AreYouSureDefaultComponent, {
        disableClose: true,
        data: {
          config: DEFAULT_ARE_YOU_SURE_OVERLAY_CONFIGURATION,
        },
      });
      return firstValueFrom(this.areYouSureDialogRef.afterClosed())
        .then(async (confirmed: boolean) => {
          if (!confirmed) return false;
          const deleted =
            confirmed &&
            (await firstValueFrom(
              this.removeElement(route, (data as any).id, params)
            )
              .then((deleteResponse) => {
                const result =
                  deleteResponse.body?.success != undefined &&
                  deleteResponse.body.success;
                if (result) this.snackbar.successSnackbar('Deleted.');
                else this.snackbar.errorSnackbar('Could not delete resource.');
                return result;
              })
              .catch((err: any) => {
                this.logger.logError('Delete error occured.', err);
                return false;
              }));
          return deleted;
        })
        .catch(() => {
          this.logger.logError('Error occured in are you sure dialog.');
          return false;
        });
    }
  }

  deleteMultiple(route: string, data: DatabaseModel[]): Promise<boolean> {
    this.areYouSureDialogRef = this.dialog.open(AreYouSureDefaultComponent, {
      disableClose: true,
    });
    return firstValueFrom(this.areYouSureDialogRef.afterClosed())
      .then(async (choice: boolean) => {
        const deleted =
          choice &&
          (await firstValueFrom(
            this.http
              .delete<DeleteResult>(route, {
                headers: this.getAuthHeader(),
                body: {
                  id: data.map((e: DatabaseModel) => e.id),
                },
                observe: 'response',
              })
              .pipe(share())
              .pipe((e) => this.errorPipe(e))
          )
            .then((deleteResponse: HttpResponse<DeleteResult>) => {
              const result =
                deleteResponse.body?.success != undefined &&
                deleteResponse.body.success &&
                (!deleteResponse.body.errors ||
                  deleteResponse.body.errors.length == 0);
              if (!result) {
                this.logger.logError(deleteResponse.body?.errors);
                this.snackbar.errorSnackbar('Kon niet alle data verwijderen.');
              }
              return result;
            })
            .catch((err) => {
              this.logger.logError(err);
              return false;
            }));
        return deleted;
      })
      .catch((err) => {
        this.logger.logError(err);
        return false;
      });
  }

  private removeElement(
    route: string,
    id: number,
    params: IQueryParameter[] = []
  ): Observable<HttpResponse<DeleteResult>> {
    return this.http
      .delete<DeleteResult>(
        params != undefined && params.length > 0
          ? this.queryParameters.addQueryParameters(route, params)
          : route,
        {
          headers: this.getAuthHeader(),
          body: { id: id },
          observe: 'response',
        }
      )
      .pipe(share())
      .pipe((e) => this.errorPipe(e));
  }

  private delete<DeleteModel>(
    route: string,
    body: DeleteModel,
    params: IQueryParameter[] = []
  ) {
    return this.http.delete<DeleteResult>(
      params != undefined && params.length > 0
        ? this.queryParameters.addQueryParameters(route, params)
        : route,
      {
        headers: this.getAuthHeader(),
        body: body,
        observe: 'response',
      }
    );
  }

  // PIPES
  private errorPipe<T>(observable: Observable<HttpResponse<T>>) {
    this.sub.subscribe(observable.pipe(share()), {
      error: (err: HttpErrorResponse) => {
        try {
          this.logger.logError('HTTP ERROR', err);
          this.snackbar.errorSnackbar(
            this.config.httpErrorMessages[err.status] ??
              `UNKNOWN ERROR OCCURRED. (${err.status}: ${err.statusText})`
          );

          if (err.status == HTTP_ERROR_CODE.UNAUTHORIZED) {
            sessionStorage.clear();
            this.unauthorizedEvent.emit();
          }
        } catch {
          this.logger.logError(
            'ONBEKENDE HTTP ERROR RESULT',
            err.status,
            err.statusText
          );
          this.snackbar.errorSnackbar(this.config.httpErrorMessages[0]);
        }
      },
    });
    return observable;
  }

  // HEADERS
  private getAuthHeader() {
    const authHeader = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem(
        this.env.sessionStorageBearerKey
      )}`,
    });
    return authHeader;
  }
}
