export class VNSCoreConfig implements VNSCoreConfig {
  role: 'admin' | 'member';
  constructor(role: 'admin' | 'member') {
    this.role = role;
  }
}

export interface VNSCoreConfig {
  role: 'admin' | 'member';
}

export type ForbiddenPageConfig =
  | {
      html: string;
    }
  | {
      message: string;
    };
