import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarServiceConfiguration, ISnackbarConfiguration } from './config';
import { ActionSnackbarComponent } from './components/action-snackbar/action-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService implements OnDestroy {
  openSnackbar?: MatSnackBarRef<ActionSnackbarComponent>;

  constructor(private config: SnackbarServiceConfiguration, private snackbar: MatSnackBar) {}

  ngOnDestroy(): void {
    this.openSnackbar?.dismiss();
  }
  close() {
    this.openSnackbar?.dismiss();
  }

  private _snackbar<T = any>(
    message: string,
    snackbarConfig: ISnackbarConfiguration,
    actions: ActionSnackbarData['actions'],
    config?: MatSnackBarConfig<T>
  ): MatSnackBarRef<ActionSnackbarComponent> {
    let c: MatSnackBarConfig<T> = {
      duration: snackbarConfig.duration,
      panelClass: snackbarConfig.panelClass,
    };

    if (config) c = { ...c, ...config };
    // this.snackbar.open()
    this.openSnackbar = this.snackbar.openFromComponent<
      ActionSnackbarComponent,
      ActionSnackbarData
    >(ActionSnackbarComponent, {
      data: {
        message,
        actions,
      },
    });
    return this.openSnackbar;
  }

  errorSnackbar<T = any>(
    message: string,
    actions = [{ text: this.config.errorSnackbar.defaultButtonText }],
    config?: MatSnackBarConfig<T>
  ): MatSnackBarRef<ActionSnackbarComponent> {
    return this._snackbar<T>(message, this.config.errorSnackbar, actions, config);
  }

  successSnackbar<T = any>(
    message: string,
    actions = [{ text: this.config.errorSnackbar.defaultButtonText }],
    config?: MatSnackBarConfig<T>
  ): MatSnackBarRef<ActionSnackbarComponent> {
    return this._snackbar<T>(message, this.config.successSnackbar, actions, config);
  }

  infoSnackbar<T = any>(
    message: string,
    actions = [{ text: this.config.errorSnackbar.defaultButtonText }],
    config?: MatSnackBarConfig<T>
  ): MatSnackBarRef<ActionSnackbarComponent> {
    return this._snackbar<T>(message, this.config.infoSnackbar, actions, config);
  }
}
export interface ActionSnackbarData {
  message: string;
  actions: { text: string; routerLink?: string | string[] }[];
  colors?: string;
}
