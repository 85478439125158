/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { CA_ENVIRONMENT } from './environment.token';
import { CaEnvironment } from '@ca/ca-utils';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  production: boolean;
  constructor(@Inject(CA_ENVIRONMENT) private env: CaEnvironment) {
    this.production = this.env.production;
    if (!this.production) this.log('Logging enabled');
  }
  log(...msg: any | any[]): void {
    if (!this.production) console.log(...msg);
  }
  logError(...msg: any | any[]) {
    if (!this.production) console.error(...msg);
  }
}
