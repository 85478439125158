import { HttpClient } from '@angular/common/http';
import { VATCheckData } from './VATCheckData';
import { Injectable } from '@angular/core';

@Injectable()
export class VATCheckService {
  constructor(private http: HttpClient) {}

  /**
   * Validate a VAT number and get data
   * @param vat_number VAT Ex. BE0442697805
   * @returns VATCheckData
   */
  CheckVAT(vat_number: string) {
    return this.http.get<VATCheckData>(
      `https://controleerbtwnummer.eu/api/validate/${vat_number}.json`
    );
  }
}
