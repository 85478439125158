import { ActionReducer, ActionReducerMap, INIT, MetaReducer } from '@ngrx/store';

import * as fromLoading from './reducers/loading.reducer';
import * as fromApp from './reducers/app.reducer';
import * as fromPages from './reducers/pages.reducer';
import * as fromNews from './reducers/news.reducer';
import * as fromTickets from './reducers/my-file.reducer';

export interface VNSWebsiteState {
  loading: fromLoading.LoadingState;
  app: fromApp.AppState;
  pages: fromPages.PagesState;
  news: fromNews.NewsState;
  myFile: fromTickets.MyFileState;
}

export const ROOT_REDUCER: ActionReducerMap<VNSWebsiteState> = {
  loading: fromLoading.reducer,
  app: fromApp.reducer,
  pages: fromPages.reducer,
  news: fromNews.reducer,
  myFile: fromTickets.reducer,
};

const log_prefix = '< Store >\n$';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...args: any): void => console.log(`${log_prefix}`, ...args);

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    log('state', state);
    log('action', action);
    return reducer(state, action);
  };
}
// hydration.reducer.ts

export const hydrationMetaReducer = (
  reducer: ActionReducer<VNSWebsiteState>
): ActionReducer<VNSWebsiteState> => {
  return (state, action) => {
    if (action.type === INIT) {
      const storageValue = localStorage.getItem('state');
      if (storageValue !== null) {
        try {
          return JSON.parse(storageValue);
        } catch (err) {
          localStorage.removeItem('state');
        }
      }
    }
    const nextState = reducer(state, action);
    localStorage.setItem('state', JSON.stringify(nextState));
    return nextState;
  };
};

// export const metaReducers: MetaReducer<VNSWebsiteState>[] = [hydrationMetaReducer];
export const metaReducers: MetaReducer<VNSWebsiteState>[] = [];
