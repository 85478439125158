import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError } from 'rxjs';
import { OnboardingState } from '../types';
import { UnauthorizedHttpRequest } from '../store/actions';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private store: Store<{ onboarding: OnboardingState }>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          this.store.dispatch(
            UnauthorizedHttpRequest({
              url: request.urlWithParams,
              error: error,
            })
          );
        }
        throw error;
      })
    );
  }
}
