import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';
import { LoadPage, LoadPageFailure, LoadPageSuccess } from '../reducers/pages.reducer';
import { CaSubscriber } from '@ca/ca-utils';
import { DOCUMENT } from '@angular/common';
import { PageID } from '@ca/vns-models';

@Injectable()
export class PagesEffects implements OnDestroy {
  private sub = new CaSubscriber();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private actions$: Actions,
    private cms: CmsService
  ) {
    this.sub.subscribe(this.actions$.pipe(ofType(LoadPageSuccess)), {
      next: (pageData) => {
        console.log('page loaded successfully!');
        if (pageData)
          this.document.body.classList.value = `ca-typography ${PageID[
            pageData.res.id
          ].toLocaleLowerCase()}`;
        else this.document.body.classList.value = 'ca-typography';
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  onLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadPage),
      switchMap(({ metaUrl }) =>
        this.cms
          .getPage(metaUrl)
          .then((res) => LoadPageSuccess({ res, metaUrl }))
          .catch((err) => LoadPageFailure({ error: err }))
      )
    )
  );
}
