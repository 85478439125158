import { Component, Input, OnInit } from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { onlyUniqueIndexedNames } from '@ca/ca-utils';
import { NewsArticle, NewsGalleryPlugin, Tag, TagsGroup } from '@ca/vns-models';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'ca-news-gallery',
  template: `<ng-container *ngFor="let tgroup of tagGroups">
      <div class="news-filter-options">
        <span class="tag_group_title">{{ tgroup.name }}</span>
        <mat-chip-listbox
          aria-label="grouped filter selection"
          multiple
          (change)="filterChange($event, tgroup.id)">
          <mat-chip-option
            *ngFor="let tag of tgroup.tags"
            [value]="{ id: tag.id, name: tag.name, group_id: tgroup.id }"
            >{{ tag.name }}</mat-chip-option
          >
        </mat-chip-listbox>
      </div>
    </ng-container>

    <ng-container *ngFor="let article of filteredArticles | async">
      <ca-news-article-summary [article]="article"></ca-news-article-summary>
    </ng-container>

    <ng-container *ngIf="((filteredArticles | async) ?? []).length === 0">
      <p class="error-msg">{{ errorText }}</p>
    </ng-container>

    <!-- TODO: Pagination -->
    <!-- <a *ngIf="plugin.cta" class="cta-btn" [href]="plugin.cta.link">{{ plugin.cta.title }}</a> --> `,
})
export class NewsGalleryComponent implements OnInit {
  // TODO: pagination for articles
  @Input() newsPlugin!: NewsGalleryPlugin;
  protected errorText =
    'Oeps! Geen artikelen gevonden. Probeer andere filters voor meer resultaten.';
  protected activeFilters: Filter[] = [];
  private articlesBS = new BehaviorSubject<NewsArticle[]>([]);
  protected filteredArticles: Observable<NewsArticle[]> = this.articlesBS.asObservable();
  protected tagGroups: TagsGroup[] = [];

  ngOnInit(): void {
    if (!this.newsPlugin) throw new Error('News plugin data not provided!');
    this.tagGroups = ConstructAllTagGroups(this.newsPlugin.articles);
    this.articlesBS.next(this.newsPlugin.articles);
  }

  filterChange(e: MatChipListboxChange, groupId: number) {
    this.activeFilters = [
      ...this.activeFilters.filter((t) => t.group_id !== groupId),
      ...e.value,
    ].filter(onlyUniqueIndexedNames);
    const predicate = createFilterPredicate(groupActiveFilters(this.activeFilters));
    this.articlesBS.next(this.newsPlugin.articles.filter(predicate));
  }
}

function groupActiveFilters(xs: Filter[]) {
  return xs.reduce(function (rv: GroupedFilters, x) {
    (rv[x.group_id] = rv[x.group_id] || []).push(x);
    return rv;
  }, {});
}

function ConstructAllTagGroups(articles: NewsArticle[]): TagsGroup[] {
  const tag_groups_dirty = articles.flatMap((a) => a.tag_groups);
  const tag_groups: TagsGroup[] = [];
  tag_groups_dirty.forEach((tgroup) => {
    const existingIndex = tag_groups.findIndex((tg) => tg.id === tgroup.id);
    if (existingIndex !== -1) {
      tag_groups[existingIndex].tags = [...tag_groups[existingIndex].tags, ...tgroup.tags].filter(
        onlyUniqueIndexedNames
      );
    } else tag_groups.push({ ...tgroup });
  });
  // TODO: sort tags alphabetically
  tag_groups.forEach((tagGroup) => {
    tagGroup.tags = tagGroup.tags.sort((a, b) => a.name.localeCompare(b.name));
  });
  return tag_groups;
}

export function filterArticles(newsArticles: NewsArticle[], activeFilters: Filter[]) {
  const groupedActiveFilters = groupActiveFilters(activeFilters);
  const _articles =
    Object.keys(groupedActiveFilters).length > 0
      ? newsArticles.filter(createFilterPredicate(groupedActiveFilters))
      : newsArticles;
  return _articles;
}

/**
 * Creates a filter predicate function based on active filters.
 * @param groupedActiveFilters - An array of active CaFilter objects, grouped on groupName.
 * @returns A filter predicate function for use with Array.filter().
 */
export function createFilterPredicate(groupedActiveFilters: GroupedFilters) {
  return function (article: NewsArticle): boolean {
    return Object.values(groupedActiveFilters).every((group) => {
      return group.some((activeFilter) =>
        article.tag_groups.some(
          (articleTagGroup) =>
            articleTagGroup.id === activeFilter.group_id &&
            articleTagGroup.tags.findIndex((t) => t.id === activeFilter.id) !== -1 // article should have at least one active filter in each active filter group
        )
      );
    });
  };
}

export type Filter = Tag & { group_id: number };

export interface GroupedFilters {
  [groupName: string]: Filter[];
}
