// import { onboardingActions } from '@ca/onboarding';
import { createReducer, on, props } from '@ngrx/store';
import { createAction } from '@ngrx/store';

export interface LoadingState {
  loading: boolean;
}
export const startLoading = createAction('[LOADING] Start loading');

export const stopLoading = createAction('[LOADING] Stop loading');

export const setLoading = createAction('[LOADING] SET', props<{ loading: boolean }>());

export const initialState: LoadingState = { loading: false };

export const reducer = createReducer(
  initialState,
  on(startLoading, (_) => ({ ..._, loading: true })),
  on(stopLoading, (_) => ({ ..._, loading: false })),
  on(setLoading, (_, { loading }) => ({ ..._, loading: loading }))
  // on(onboardingActions.logout, () => initialState)
);
