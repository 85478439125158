import { Component, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { PartialObserver } from 'rxjs';
import { OnboardingService } from '../../services/onboarding.service';
import { IRegisterMessages, OnboardingModuleConfiguration } from '../../types';
import { SnackbarService } from '@ca/ca-snackbar';
import { CaSubscriber } from '@ca/ca-utils';
import { LoggingService } from '@ca/ca-ng-core';

@Component({
  selector: 'ca-register',
  template: `
    <div class="form-container onboarding-container">
      <form
        class="onboarding-form register-form"
        [formGroup]="registerForm"
        (ngSubmit)="onSubmit()"
      >
        <mat-form-field>
          <mat-label>{{ msgs.form.firstname }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="firstName"
            (keyup)="capitalizeName($event)"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ msgs.form.lastname }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="lastName"
            (keyup)="capitalizeName($event)"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ msgs.form.email }}</mat-label>
          <input matInput type="e-mail" formControlName="email" />
        </mat-form-field>

        <button mat-button type="submit" class="success">
          {{ msgs.form.register_button_text }}
        </button>
      </form>
    </div>
  `,
})
export class RegisterComponent implements OnDestroy {
  sub: CaSubscriber = new CaSubscriber();
  msgs: IRegisterMessages;

  registerForm: FormGroup = this.fb.group({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  constructor(
    private config: OnboardingModuleConfiguration,
    private fb: FormBuilder,
    private onboarding: OnboardingService,
    private snackbar: SnackbarService,
    private logger: LoggingService
  ) {
    this.msgs = this.config.messages.register;
  }

  registerObserver: PartialObserver<any> = {
    next: (res) => {
      // TODO: implement
      console.log('TODO: implement register');
    },
    error: (err) => this.logger.logError(err),
  };

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  capitalizeName(e: any) {
    e.target.value = capitalizeWord(e.target.value);
  }

  onSubmit() {
    if (this.registerForm.valid)
      // TODO: dispatch register action
      this.sub.subscribe(
        this.onboarding.register(this.registerForm.value),
        this.registerObserver
      );
    else this.snackbar.errorSnackbar(this.msgs.form.invalid);
  }
}

export const capitalizeWord = (word: string) => {
  if (!word) return word;
  return word[0].toUpperCase() + word.substring(1).toLocaleLowerCase();
};
