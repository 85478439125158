import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthService } from './auth.service';
import { AuthGuardService } from '@ca/onboarding';
import { LoginComponent } from './components/login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { CaNgCoreModule } from '@ca/ca-ng-core';
import { StoreModule } from '@ngrx/store';
import { FEATURE_KEY } from './config';
import { metaReducers, ROOT_REDUCER } from './store';
import { LoginEffects } from './store/login.effects';
import { EffectsModule } from '@ngrx/effects';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { VNSCoreConfig } from './VNSCoreConfig';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { TicketStatusChipComponent } from './components/ticket-status-chip/ticket-status-chip.component';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_KEY, ROOT_REDUCER, {
      metaReducers,
    }),
    EffectsModule.forFeature([LoginEffects]),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    CaNgCoreModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    AuthService,
    AuthGuardService,
  ],
  declarations: [LoginComponent, ForbiddenComponent, TicketStatusChipComponent],
  exports: [ForbiddenComponent, TicketStatusChipComponent, LoginComponent],
})
export class VnsNgCoreModule {
  static forRoot(configuration: VNSCoreConfig) {
    return {
      ngModule: VnsNgCoreModule,
      providers: [
        {
          provide: VNSCoreConfig,
          useValue: configuration,
        },
      ],
    };
  }
}
