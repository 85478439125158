export enum HTTP_ERROR_CODE {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  INSUFFICIENT_PERMISSIONS = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  IN_USE = 420,
  NAME_NOT_UNIQUE = 427,
  DISSALLOWED_MERGE_TAG = 430,
  INTERNAL_SERVER_ERROR = 500,
}
