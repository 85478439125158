export type VNSEnvironment = {
  appUrl: string;
  backendUrl: string;
  recaptchaSiteKey: string;
  stripePublicKey: string;
  production: boolean;
  apiBaseUrl: string;
  sessionStorageBearerKey: string;
  baseUrl: string;
};
export const environment: VNSEnvironment = {
  production: true,
  apiBaseUrl: 'https://backend.vns.dev.cigar.agency/',
  baseUrl: 'https://backend.vns.dev.cigar.agency/',
  appUrl: '/',
  backendUrl: 'https://service.vns.dev.cigar.agency',
  sessionStorageBearerKey: 'ca-token',
  recaptchaSiteKey: '6LeuRAcqAAAAAJyLKIhuqnZxyHiXd_WritDsTd5g',
  stripePublicKey:
    'pk_test_51PdXNJRvbFGrm0OiYYXZaAGT7tdtqLXZLAz3D4FTTiK3GCD5YccI74gPdvKDSRuwPBKUPpjZmIeUo1koRzJ7s2yb00TtHbigfw',
};
