import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbs: Array<BreadcrumbSegment> = [];
  private bs = new BehaviorSubject<BreadcrumbSegment[]>([]);
  Breadcrumbs$ = this.bs.asObservable();
  // TODO: no breadcrumb for home

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      this.bs.next(this.breadcrumbs);
    });
  }

  setDynamicSegmentTitle(title: string) {
    const current = this.breadcrumbs.pop();
    if (!current) throw new Error('Could not get current segment from breadcrumb');
    current.label = title;
    this.breadcrumbs = [...this.breadcrumbs, current];
    this.bs.next(this.breadcrumbs);
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: Array<BreadcrumbSegment> = []
  ): Array<BreadcrumbSegment> {
    // console.log('creating breadcrumbs', this.breadcrumbs);
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      if (child.snapshot.data['breadcrumb'])
        breadcrumbs.push({ label: child.snapshot.data['breadcrumb'], url: url });
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}

export interface BreadcrumbSegment {
  label: string;
  url: string;
}
