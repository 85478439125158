import { createSelector } from '@ngrx/store';
import { PageID } from '@ca/vns-models';
import { PagesState } from './reducers/pages.reducer';
import { AppState } from './reducers/app.reducer';
import { NewsState } from './reducers/news.reducer';
import { MyFileState } from './reducers/my-file.reducer';

export const selectPages = (state: { pages: PagesState }) => state.pages;
export const selectApp = (state: { app: AppState }) => state.app;

export const selectProtectedRoutes = (state: { app: AppState }) => state.app.protectedRoutes;

export const selectMetaUrlForPage = (pageID: PageID) =>
  createSelector(selectApp, (s) => s.routes.find((r) => r.pageID === pageID));

export const selectNews = (state: { news: NewsState }) => state.news;

/**
 * Dossiers
 */
export const selectFileDetail = (state: { myFile: MyFileState }) => state.myFile.detail;
