import { createAction, props } from '@ngrx/store';
import {
  IUserDivision,
  IUserProfile,
  IUserRole,
  PutUpdateProfileRequest,
  PutUpdateUserResult,
  PutUpsertUserRequest,
} from '../../types';
import { OnboardingAction } from '../config';
import { DeleteRequest, DeleteResult } from '@ca/ca-data';

export const loadProfile = createAction(OnboardingAction.LOAD_PROFILE);
export const loadProfileSuccess = createAction(
  OnboardingAction.LOAD_PROFILE_SUCCESS,
  props<{ profile: IUserProfile }>()
);
export const updateProfile = createAction(
  OnboardingAction.UPDATE_PROFILE,
  props<{ request: PutUpdateProfileRequest; current: IUserProfile }>()
);
export const updateProfileSuccess = createAction(
  OnboardingAction.UPDATE_PROFILE_SUCCESS,
  props<{ request: PutUpdateProfileRequest; current: IUserProfile }>()
);

export const loadUsers = createAction(OnboardingAction.LOAD_USERS);
export const loadUsersSuccess = createAction(
  OnboardingAction.LOAD_USERS_SUCCESS,
  props<{ users: IUserProfile[] }>()
);

export const openUpsertUserModal = createAction(
  OnboardingAction.OPEN_UPSERT_USER,
  props<IUserProfile>()
);
export const upsertUser = createAction(OnboardingAction.UPSERT_USER, props<PutUpsertUserRequest>());
export const upsertUserSuccess = createAction(
  OnboardingAction.UPSERT_USER_SUCCESS,
  props<{ request: PutUpsertUserRequest; response: PutUpdateUserResult }>()
);

export const openRemoveUserModal = createAction(
  OnboardingAction.OPEN_REMOVE_USER,
  props<IUserProfile>()
);
export const removeUser = createAction(OnboardingAction.REMOVE_USER, props<DeleteRequest>());
export const removeUserSuccess = createAction(
  OnboardingAction.REMOVE_USER_SUCCESS,
  props<{ request: DeleteRequest; response: DeleteResult }>()
);

export const loadDivisions = createAction(OnboardingAction.LOAD_DIVISIONS);
export const loadDivisionsSuccess = createAction(
  OnboardingAction.LOAD_DIVISIONS_SUCCESS,
  props<{ divisions: IUserDivision[] }>()
);
export const loadRoles = createAction(OnboardingAction.LOAD_ROLES);
export const loadRolesSuccess = createAction(
  OnboardingAction.LOAD_ROLES_SUCCESS,
  props<{ roles: IUserRole[] }>()
);

// export const checkHeartbeat = createAction(OnboardingAction.HEARTBEAT);
