import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort, Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { defaultUserProfile } from '../../config/defaults';
import {
  loadUsers,
  openRemoveUserModal,
  openUpsertUserModal,
  upsertUser,
} from '../../store/actions';
import { IUserProfile, OnboardingState, PutUpsertUserRequest } from '../../types';
import { selectAccessLevel, selectIsUserManager, selectUserId, selectUsers } from '../../store';
import { CaSubscriber } from '@ca/ca-utils';

@Component({
  selector: 'ca-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UsersTableComponent implements OnInit {
  // TODO: move to service?
  isUserManager$?: Observable<boolean>;
  accessLevel = 1000;
  displayedColumns: string[] = ['actions', 'name', 'active', 'email', 'expand'];
  data: IUserProfile[] = [];
  isLoadingResults = true;
  expandedElement: IUserProfile | null = null;
  activeUserId?: number | string;
  private sort?: MatSort;
  private sub = new CaSubscriber();

  @ViewChild(MatSort) set sortContent(content: MatSort) {
    if (content && !this.sort) {
      this.sort = content;
      this.sort.sortChange.subscribe(
        (sort: Sort) => (this.data = [...this.data].sort((a, b) => sortUsers(a, b, sort)))
      );
    }
  }

  constructor(private store: Store<{ onboarding: OnboardingState }>) {}

  ngOnInit(): void {
    this.isUserManager$ = this.store.select(selectIsUserManager);

    this.sub.subscribe(this.store.select(selectAccessLevel), {
      next: (al: number | undefined) => (this.accessLevel = al ?? 1000),
    });

    this.store.select(selectUserId).subscribe((id?: number | string) => (this.activeUserId = id));
    firstValueFrom(this.isUserManager$).then((bit: boolean) => {
      if (bit) this.store.dispatch(loadUsers());
    });

    this.store.select(selectUsers).subscribe((res) => {
      if (res) {
        this.data = [...res];
        this.isLoadingResults = false;
      }
    });
  }

  onToggleActiveRow(event: Event, element: IUserProfile) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  onActiveChange(event: MatSlideToggleChange, user: IUserProfile & { type: any }) {
    const copy = { ...user };
    delete copy.passwordStatus;
    delete copy.readOnly;
    delete copy.type;
    const update: PutUpsertUserRequest = {
      ...copy,
      linkedDivisions: user.linkedDivisions.map((e) => ({
        rolesId: e.role.id,
        jobTitle: e.jobTitle,
        divisionsId: e.division.id,
      })),
      active: event.checked,
    };
    this.store.dispatch(upsertUser(update));
  }

  addUser = () => this.store.dispatch(openUpsertUserModal(defaultUserProfile));
  editUser = (user: IUserProfile) => this.store.dispatch(openUpsertUserModal(user));
  deleteUser = (user: IUserProfile) => this.store.dispatch(openRemoveUserModal(user));
}

const sortUsers = (a: IUserProfile, b: IUserProfile, sort: Sort) => {
  const sortKey = (sort.active === 'name' ? 'firstName' : sort.active) as keyof IUserProfile;
  let res = 0;
  const a_ = a[sortKey];
  const b_ = b[sortKey];

  switch (sortKey) {
    case 'firstName':
    case 'email':
      res = a_ && b_ ? (a_ > b_ ? 1 : b_ > a_ ? -1 : 0) : a_ && !b_ ? 1 : -1;
      break;
    case 'id':
      res =
        typeof a.id === 'string' && typeof b.id === 'string'
          ? a.id.localeCompare(b.id)
          : (a.id as number) - (b.id as number);
      break;
    case 'active':
      res = (a_ && b_) || (a_ === true && b_ === true) ? 0 : a_ && !b_ ? 1 : b_ && !a_ ? -1 : 0;
      break;
    default:
      return 0;
  }

  return sort.direction === 'desc' ? res * -1 : res;
};
export interface UsersTableRow {
  name: string;
  id: number;
  active: boolean;
  email: string;
}
