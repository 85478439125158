<ng-container *ngIf="detail$ | async as detail">
  <div class="header">
    <h1>{{ detail.title }}</h1>
    <mat-chip>{{ detail.status }}</mat-chip>
  </div>

  <div class="props">
    <div class="prop">
      <span class="name">Datum van aanvraag</span>
      <span class="value">{{ detail.requested_at | date }}</span>
    </div>
    <!-- TODO: sub_category -->
    <div class="prop">
      <span class="name">Status</span>
      <span class="value">
        <mat-chip>{{ detail.status }}</mat-chip>
      </span>
    </div>
    <div class="prop" *ngIf="detail.messages.length > 0">
      <span class="name">Mijn vraag</span>
      <span class="value">{{ detail.messages[0].message }}</span>
    </div>
    <div class="prop" *ngIf="detail.attachments.length > 0">
      <span class="name">Bijlagen</span>
      <span class="value" *ngFor="let att of detail.attachments">{{ att.filename }}</span>
    </div>
  </div>
</ng-container>
<ca-conversation [owner_id]="user?.id" (newMessage)="sendMessage($event)"></ca-conversation>
