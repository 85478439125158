import { Inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OnboardingState, UserState } from '../../types';
import { OnboardingAction } from '../config';
import { selectUser } from '../selectors';
import { CaSubscriber, IEnvironmentBase } from '@ca/ca-utils';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Injectable()
export class PreserverEffects {
  private sub = new CaSubscriber();

  private userObserver = {
    next: (user?: UserState) => {
      if (user && user.token) sessionStorage.setItem('ca-user', JSON.stringify(user));
    },
  };

  private logoutObserver = {
    next: () => {
      sessionStorage.removeItem('ca-user');
    },
  };

  constructor(
    @Inject(CA_ENVIRONMENT) private env: IEnvironmentBase,
    private store: Store<{ onboarding: OnboardingState }>,
    private actions$: Actions
  ) {
    this.sub.subscribe(this.store.select(selectUser), this.userObserver);
    this.sub.subscribe(
      this.actions$.pipe(
        ofType(
          OnboardingAction.LOGOUT,
          OnboardingAction.UNAUTHORIZED_HTTP_REQUEST,
          OnboardingAction.UNAUTHORIZED_PAGELOAD
        )
      ),
      this.logoutObserver
    );
  }
}
