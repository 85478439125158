import { Injectable } from '@angular/core';

import { LoggingService } from './logging.service';
import { IQueryParameter } from '@ca/ca-utils';

@Injectable({
  providedIn: 'root',
})
export class QueryParametersService {
  constructor(private logger: LoggingService) {}
  /**
   *
   * @param url to add querystring to (always appends ?)
   * @param params to create querystring from
   * @returns url with querystring portion
   */
  addQueryParameters(url: string, params: IQueryParameter[], appendQM = true) {
    const paramsToAdd = params.filter(
      (e) =>
        e.value !== null &&
        e.value !== undefined &&
        e.value !== '' &&
        e != EMPTY_QUERY_PARAM
    );
    return (
      `${url}${appendQM ? '?' : ''}` + this.getParameterString(paramsToAdd)
    );
  }

  private getParameterString(params: IQueryParameter[]) {
    let paramString = '';
    params.forEach((e: IQueryParameter, id: number) => {
      paramString += `${e.name}=${e.value}${id < params.length - 1 ? '&' : ''}`;
    });
    return paramString;
  }
}

export const EMPTY_QUERY_PARAM: IQueryParameter = {
  name: '',
  value: '',
};
