import { Page } from '@ca/vns-models';
import { createAction, createReducer, on, props } from '@ngrx/store';

export interface PagesState {
  [metaUrl: string]: Page;
}

export const initialState: PagesState = {};

export enum PageActions {
  loadPage = '[Page] Load',
  loadPageSucces = '[Page] Loaded successfully',
  loadPageFailure = '[Page] Failed to load',
}

export const LoadPage = createAction(PageActions.loadPage, props<{ metaUrl: string }>());

export const LoadPageSuccess = createAction(
  PageActions.loadPageSucces,
  props<{ metaUrl: string; res: Page }>()
);

export const LoadPageFailure = createAction(
  PageActions.loadPageFailure,
  props<{ error: string }>()
);

export const reducer = createReducer(
  initialState,
  on(LoadPageSuccess, (_, { metaUrl, res }) => ({
    ..._,
    [metaUrl]: res,
  }))
);
