import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { OnboardingForm } from './OnboardingForm';

export class LoginForm extends OnboardingForm {
  get data(): FormData {
    const formData = new FormData();
    formData.append('login', this.login);
    formData.append('pass', this.password);
    return formData;
  }

  get login() {
    return this.formGroup.controls['login'].value;
  }
  get password() {
    return this.formGroup.controls['pass'].value;
  }

  constructor(fb: FormBuilder) {
    super();
    this.formGroup = fb.group({
      login: new FormControl('', [Validators.required]),
      pass: new FormControl('', [Validators.required]),
    });
  }
}
