import { IEditProfileMessages } from './IEditProfileMessages';
import { ILoginMessages } from './ILoginMessages';
import { ILogoutMessages } from './ILogoutMessages';
import { IRegisterMessages } from './IRegisterMessages';
import { IResetPasswordMessages } from './IResetPasswordMessages';
import { ISetPasswordMessages } from './ISetPasswordMessages';
import { ITwoFaDialogMessages } from './ITwoFaDialogMessages';

export interface IOnboardingMessages {
  generalFailure: string;
  sessionExpired: string;
  login: ILoginMessages;
  logout: ILogoutMessages;
  twoFa: ITwoFaDialogMessages;
  editProfile: IEditProfileMessages;
  register: IRegisterMessages;
  resetPassword: IResetPasswordMessages;
  setPassword: ISetPasswordMessages;
}
