export interface IEditProfileMessages {
  form: {
    firstname: string;
    lastname: string;
    job_title: string;
    email: string;
    mobile: string;
    telephone: string;
    submit_button_text: string;
  };
  slider: string;
  reset_button_tooltip: string;
  reset_button_text: string;
  reset_2fa_button_tooltip: string;
  reset_2fa_button_text: string;
  uploader_text: string;
  upload_button_text: string;
  cancel_button_text: string;
  remove_button_text: string;
  updated: string;
  update_failed: string;
  profile_undefined_error: string;
  activated_2fa_success: string;
  deactivated_2fa_success: string;
  activated_2fa_failure: string;
  deactivated_2fa_failure: string;
  reset_link_sent: string;
  reset_link_failure: string;
}
