import { CaDataResponse, PutResult } from '@ca/ca-data';
import { Locale, Address } from '@ca/ca-ng-core';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface LoginResponse {
  success: boolean;
  type: string;
  status?: string;
  bearerToken?: string;
  twoFaEnabled?: boolean;
  twoFaDeviceLinked?: boolean;
  profile?: IUserProfile;
  debug: boolean;
}

export interface IUserProfile {
  id: number | string;

  firstName: string;
  lastName: string;

  email: string;
  gsm: string;
  tel: string;

  preferredLanguage: Locale;
  twoFaEnabled: boolean;

  twoFaDeviceLinked?: boolean;
  readOnly?: boolean;
  linkedDivisions: ILinkedDivision[];
  avatar?: string;
  active?: boolean;
  passwordStatus?: 'ok' | 'create' | 'mail-sent';
}

export interface ILinkedDivision {
  id: number;
  jobTitle: string;
  division: IUserDivision;
  role: IUserRole;
  isDefault: boolean;
  address?: Address;
}

// users
export interface GetUsersResponse extends CaDataResponse<IUserProfile[]> {}
export interface GetUserProfileResponse {
  debug: boolean;
  type: 'get-user-profile';
  profile: IUserProfile;
  success: boolean;
}

export interface PutUpdateProfileRequest {
  login?: string;
  pass?: string;
  preferredLanguage: Locale;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  gsm: string;
  tel: string;
  twoFaEnabled: boolean;
  twoFaDeviceLinked: boolean;
}
export interface PutUpsertUserRequest {
  id: number | string;
  login?: string;
  active?: boolean;
  linkedDivisions?: {
    rolesId: number;
    divisionsId: number;
    jobTitle: string;
  }[];
  preferredLanguage: Locale;
  firstName: string;
  lastName: string;
  email: string;
  gsm: string;
  tel?: string;
  twoFaEnabled?: boolean;
  twoFaDeviceLinked?: boolean;
}
export interface PutUpdateProfileResult extends PutResult<'put-update-profile'> {}

export interface PutUpdateUserResult extends PutResult<'put-user'> {}

//divisions
export interface IUserDivision {
  //get and upsert
  id: number;
  name: string;
}

export interface GetDivisions extends CaDataResponse<IUserDivision[], 'get-divisions'> {}

export interface PutDivisionResponse extends PutResult<'put-division'> {}

//roles
export interface IUserRole {
  //get and upsert
  id: number;
  name: string;
  accessLevel: number;
}
export interface GetRoles extends CaDataResponse<IUserRole[], 'get-roles'> {}

export interface PutRoleResponse extends PutResult<'put-roles'> {
  record: IRecord;
}

// link-division
export interface ILinkDivisionRequest {
  usersId: number;
  rolesId: number;
  divisionsId: number;
}

export interface IUnlinkDivisionRequest {
  usersId: number;
  divisionsId: number;
}

export interface LinkDivisionResponse extends PutResult<'put-link-division'> {}

export interface UnlinkDivisionResponse extends PutResult<'put-unlink-division'> {}

//General
export interface IRecord {
  id: number;
  name: string;
  accessLevel: number;
}
