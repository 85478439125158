import { OnboardingAppRoutes } from '../../types';
import { IOnboardingRoutes } from './IOnboardingRoutes';
import { IOnboardingMessages } from './messages/IOnboardingMessages';

export interface IOnboardingModuleConfiguration {
  routes: Partial<IOnboardingRoutes>;
  checkTokenIntervalMs: number;
  minPasswordLength: number;
  messages: Partial<IOnboardingMessages>;
  appRoutes: OnboardingAppRoutes;
  requiredAccessLevel?: number;
  requiredDivision?: number;
}
