import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { IAreYouSureOverlay } from '../interfaces/IAreYouSureOverlay';
import { AreYouSureDefaultComponent } from '../are-you-sure-default-overlay/are-you-sure-default-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class AreYouSureService {
  openDialog?: MatDialogRef<AreYouSureDefaultComponent, boolean>;

  constructor(public Dialog: MatDialog) {}

  OpenAreYouSure(
    config?: Partial<IAreYouSureOverlay>,
  ): MatDialogRef<AreYouSureDefaultComponent, boolean> {
    this.openDialog = this.Dialog.open<
    AreYouSureDefaultComponent,
      { config?: Partial<IAreYouSureOverlay> },
      boolean
    >(AreYouSureDefaultComponent, {
      disableClose: true,
      data: {
        config: config,
      },
    });
    return this.openDialog;
  }
}
