/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable, firstValueFrom, of } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { CaDataModuleConfiguration } from '../CaDataModuleConfiguration';
import { HTTP_ERROR_CODE } from '../enums/HTTP_ERROR_CODE';
import { IDataServiceCacheOptions } from '../interfaces/IDataServiceCacheOptions';
import { IDataServicePaginationOptions } from '../interfaces/IDataServicePaginationOptions';
import { PostResult } from '../interfaces/PostResult';
import { PutResult } from '../interfaces/PutResult';
import { DeleteResult } from '../interfaces/DeleteResult';
import { AreYouSureDefaultComponent } from '@ca/ca-are-you-sure';
import { SnackbarService } from '@ca/ca-snackbar';
import {
  CaSubscriber,
  CaEnvironment,
  IQueryParameter,
  DatabaseModel,
} from '@ca/ca-utils';
import { MemCacheService } from './mem-cache.service';
import {
  CA_ENVIRONMENT,
  LoggingService,
  QueryParametersService,
} from '@ca/ca-ng-core';
@Injectable({
  providedIn: 'root',
})
export class CaMemCacheDataService implements OnDestroy {
  sub: CaSubscriber = new CaSubscriber();

  areYouSureDialogRef?: MatDialogRef<AreYouSureDefaultComponent>;
  public unauthorizedEvent: EventEmitter<void> = new EventEmitter();
  constructor(
    public dialog: MatDialog,
    @Inject(CA_ENVIRONMENT) private env: CaEnvironment,
    private config: CaDataModuleConfiguration,
    private logger: LoggingService,
    private snackbar: SnackbarService,
    private http: HttpClient,
    private cache: MemCacheService,
    private queryParameters: QueryParametersService
  ) {}
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
  get<T = any>(
    route: string,
    params?: IQueryParameter[],
    cachingOptions: Partial<IDataServiceCacheOptions> = {
      skipCache: false,
      clearCache: false,
    },
    paginationOptions: Partial<IDataServicePaginationOptions> = {
      limit: undefined,
      offset: undefined,
    }
  ): Observable<HttpResponse<T>> {
    params = params ?? [];
    if (paginationOptions.limit)
      params?.push({ name: 'limit', value: paginationOptions.limit });
    if (paginationOptions.offset)
      params?.push({ name: 'offset', value: paginationOptions.offset });
    const url =
      params != undefined && params.length > 0
        ? this.queryParameters.addQueryParameters(route, params)
        : route;

    const cachedData: Observable<T> | undefined =
      cachingOptions.skipCache || !this.cache.has(url)
        ? undefined
        : this.cache.get<T>(url);

    if (cachedData != undefined)
      return cachedData.pipe(map((e) => new HttpResponse({ body: e })));
    else {
      return this.http
        .get<T>(url, { headers: this.getAuthHeader(), observe: 'response' })
        .pipe(share())
        .pipe((e: Observable<HttpResponse<T>>) => this.errorPipe(e))
        .pipe((e: Observable<HttpResponse<T>>) => this.dataCachingPipe(e, url));
    }
  }

  put<T>(
    route: string,
    putdata: T,
    additionalResourcesToFilter?: string[]
  ): Observable<HttpResponse<PutResult>> {
    this.cache.clear(
      additionalResourcesToFilter
        ? [route, ...additionalResourcesToFilter]
        : [route]
    );
    return this.http
      .put<PutResult>(route, putdata, {
        headers: this.getAuthHeader(),
        observe: 'response',
      })
      .pipe(share())
      .pipe((e) => this.errorPipe(e));
  }

  post<T>(route: string, postData: T): Observable<HttpResponse<PostResult>> {
    if (!postData) throw new Error('Data is required for POST METHOD');
    this.cache.clear([route]);
    return this.http
      .post<PostResult>(route, postData, {
        headers: this.getAuthHeader(),
        observe: 'response',
      })
      .pipe(share())
      .pipe((e) => this.errorPipe(e));
  }

  deleteSafely<T extends DatabaseModel>(
    route: string,
    data: T,
    additionalResourcesToFilter?: string[]
  ): Promise<boolean> {
    if (!(data as any).id || (data as any).id == 0) {
      this.logger.logError('Cannot delete without id');
      return firstValueFrom(of(false));
    } else {
      // open a new modal and only delete if user chooses to confirm
      this.areYouSureDialogRef = this.dialog.open(AreYouSureDefaultComponent, {
        disableClose: true,
      });
      return firstValueFrom(this.areYouSureDialogRef.afterClosed())
        .then(async (confirmed: boolean) => {
          const deleted =
            confirmed &&
            (await firstValueFrom(
              this.removeElement(
                route,
                (data as any).id,
                additionalResourcesToFilter
              )
            )
              .then((deleteResponse) => {
                const result =
                  deleteResponse.body?.success != undefined &&
                  deleteResponse.body.success;
                if (result) this.snackbar.successSnackbar('Verwijderd.');
                else this.snackbar.errorSnackbar('Kon niet verwijderen.');
                return result;
              })
              .catch((err: any) => {
                this.logger.logError('Delete error occured.', err);
                return false;
              }));
          return deleted;
        })
        .catch(() => {
          this.logger.logError('Error occured in are you sure dialog.');
          return false;
        });
    }
  }

  deleteMultiple(
    route: string,
    data: DatabaseModel[],
    additionalResourcesToFilter?: string[]
  ): Promise<boolean> {
    this.areYouSureDialogRef = this.dialog.open(AreYouSureDefaultComponent, {
      disableClose: true,
    });
    this.cache.clear(
      additionalResourcesToFilter
        ? [route, ...additionalResourcesToFilter]
        : [route]
    );
    return firstValueFrom(this.areYouSureDialogRef.afterClosed())
      .then(async (choice: boolean) => {
        const deleted =
          choice &&
          (await firstValueFrom(
            this.http
              .delete<DeleteResult>(route, {
                headers: this.getAuthHeader(),
                body: {
                  id: data.map((e: DatabaseModel) => e.id),
                },
                observe: 'response',
              })
              .pipe(share())
              .pipe((e) => this.errorPipe(e))
          )
            .then((deleteResponse: HttpResponse<DeleteResult>) => {
              const result =
                deleteResponse.body?.success != undefined &&
                deleteResponse.body.success &&
                (!deleteResponse.body.errors ||
                  deleteResponse.body.errors.length == 0);
              if (!result) {
                this.logger.logError(deleteResponse.body?.errors);
                this.snackbar.errorSnackbar('Kon niet alle data verwijderen.');
              }
              return result;
            })
            .catch((err) => {
              this.logger.logError(err);
              return false;
            }));
        return deleted;
      })
      .catch((err) => {
        this.logger.logError(err);
        return false;
      });
  }

  private removeElement(
    route: string,
    id: number,
    additionalResourcesToFilter?: string[]
  ): Observable<HttpResponse<DeleteResult>> {
    this.cache.clear(
      additionalResourcesToFilter
        ? [route, ...additionalResourcesToFilter]
        : [route]
    );
    return this.http
      .delete<DeleteResult>(route, {
        headers: this.getAuthHeader(),
        body: { id: id },
        observe: 'response',
      })
      .pipe(share())
      .pipe((e) => this.errorPipe(e));
  }

  // PIPES
  private errorPipe<T>(observable: Observable<HttpResponse<T>>) {
    this.sub.subscribe(observable.pipe(share()), {
      error: (err: HttpErrorResponse) => {
        try {
          this.logger.logError('HTTP ERROR', err);
          this.snackbar.errorSnackbar(
            this.config.httpErrorMessages[err.status] ??
              `UNKNOWN ERROR OCCURRED. (${err.status}: ${err.statusText})`
          );
          if (err.status == HTTP_ERROR_CODE.UNAUTHORIZED) {
            sessionStorage.clear();
            this.unauthorizedEvent.emit();
          }
        } catch {
          this.logger.logError(
            'ONBEKENDE HTTP ERROR RESULT',
            err.status,
            err.statusText
          );
          this.snackbar.errorSnackbar(this.config.httpErrorMessages[0]);
        }
      },
    });
    return observable;
  }

  private dataCachingPipe<T>(
    observable: Observable<HttpResponse<T>>,
    url: string
  ) {
    this.sub.subscribe(observable, {
      next: (data: HttpResponse<T>) => {
        this.cache.set(url, data.body);
      },
    });
    return observable;
  }

  // HEADERS
  private getAuthHeader() {
    const authHeader = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem(
        this.env.sessionStorageBearerKey
      )}`,
    });
    return authHeader;
  }
}
