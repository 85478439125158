import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs';
import { VNSWebsiteState } from '../../store';
import { Store } from '@ngrx/store';
import { NewsArticle } from '@ca/vns-models';
import { environment } from '../../../../environments/environment';
import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'ca-news-article',
  template: `
    <img [src]="resourceUrlBase + article?.img?.src" [alt]="article?.img?.alt" />
    <h1>{{ article?.title }}</h1>
    <mat-chip-listbox>
      <ng-container *ngFor="let tg of article?.tag_groups">
        <mat-chip *ngFor="let t of tg.tags">{{ t.name }}</mat-chip>
      </ng-container>
    </mat-chip-listbox>

    <div [innerHTML]="article?.text"></div>
  `,
})
export class NewsArticleComponent implements OnInit, OnDestroy {
  private sub: any;
  protected article?: NewsArticle;
  resourceUrlBase = environment.baseUrl;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store<VNSWebsiteState>,
    private readonly breadcrumbs: BreadcrumbService
  ) {}

  ngOnInit() {
    scrollTo({
      top: 0,
    });
    const obs = this.route.params.pipe(
      switchMap((params) =>
        this.store
          .select((s) => s.news.articles.find((e) => e.metaUrl === params['metaUrl']))
          .pipe(map((res) => res))
      )
    );

    this.sub = obs.subscribe((art) => {
      this.article = art;
      if (art) this.breadcrumbs.setDynamicSegmentTitle(art.metaTitle);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
