import { HttpHeaders } from '@angular/common/http';

export function getBearerHeaderFromLocalStorage(key: string): HttpHeaders {
  return getBearerHeader(`${sessionStorage.getItem(key)}`);
}

export function getBearerHeader(token: string): HttpHeaders {
  const authHeader = new HttpHeaders({
    Authorization: `Bearer ${token}`,
  });
  return authHeader;
}

export function UserRole<RoleType>(e: any, role: string): RoleType {
  return e[role];
}
