import { PageInfo, PageLinkInfo } from '@ca/vns-models';
import { createAction, createReducer, on, props } from '@ngrx/store';
import { PageID } from '@ca/vns-models';

export interface RouteInfo {
  pageID: PageID;
  metaUrl: string;
}

export interface AppState {
  routes: RouteInfo[];
  protectedRoutes: PageInfo[];
  publicRoutes: PageInfo[];

  primaryMenuLinks: PageLinkInfo[];
  secondaryMenuLinks: PageLinkInfo[];
  footerMenuLinks: PageLinkInfo[];
  dropdownLinks: PageLinkInfo[];
}

export const initialState: AppState = {
  protectedRoutes: [],
  publicRoutes: [],
  primaryMenuLinks: [],
  footerMenuLinks: [],
  secondaryMenuLinks: [],
  dropdownLinks: [],
  routes: [],
};

export enum AppActions {
  loadPagesInfo = '[Basic information on Pages] Load',
  loadPagesInfoSuccess = '[Basic information on Pages] Loaded successfully',
  loadPagesInfoFailure = '[Basic information on Pages] Failed to load',
}

export const LoadPagesInfo = createAction(AppActions.loadPagesInfo);

export const LoadPagesInfoSuccess = createAction(
  AppActions.loadPagesInfoSuccess,
  props<{ pages: PageInfo[] }>()
);

export const LoadPagesInfoFailure = createAction(
  AppActions.loadPagesInfoFailure,
  props<{
    error: string;
  }>()
);

// export const LoginSuccess = createAction(AppActions.loginSuccess);

export const reducer = createReducer(
  initialState,
  on(LoadPagesInfoSuccess, (_, { pages }) => ({
    ..._,
    routes: pages.map((p) => ({ pageID: p.id, metaUrl: p.metaUrl })),
    protectedRoutes: pages
      .filter(
        (p) =>
          // p.location === 'header' &&
          p.membersOnly === true
      )
      .sort((a, b) => a.id - b.id),
    publicRoutes: pages
      .filter(
        (p) =>
          // p.location === 'header' &&
          p.membersOnly === false
      )
      .sort((a, b) => a.id - b.id),
    footerMenuLinks: pages.filter(
      (p) => p.type === 'link' && p.location === 'footer'
    ) as PageLinkInfo[],
    primaryMenuLinks: pages.filter(
      (p) => p.type === 'link' && p.location === 'header:primary'
    ) as PageLinkInfo[],
    secondaryMenuLinks: pages.filter(
      (p) => p.type === 'link' && p.location === 'header:secondary'
    ) as PageLinkInfo[],
    dropdownLinks: pages.filter(
      (p) => p.type === 'link' && p.location === 'dropdown'
    ) as PageLinkInfo[],
  }))
);
