export interface ISetPasswordMessages {
  title: string;
  form_instructions: string;
  form: {
    password: string;
    password_confirmation: string;
    submit_button_text: string;
    invalid: string;
  };
  updated: string;
  update_failed: string;
  passwords_dont_match: string;
  minLengthError: (min: number) => string;
}
