import { Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnboardingState } from '../../../types';
import { Subscription } from 'rxjs';
import { CaEnvironment } from '@ca/ca-utils';
import { IUserProfileWidgetInfo } from '../../../types/interfaces/services';
import { selectUser } from '../../../store';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Component({
  selector: 'ca-profile-widget',
  template: `
    <div *ngIf="profile" class="profile-widget" logOutOnUnauthorized>
      <div (click)="clicked.emit($event)" class="profile-widget">
        <div class="user-name">
          <p>{{ profile.firstname }} {{ profile.lastname }}</p>
        </div>
        <mat-icon>account_circle</mat-icon>
      </div>
      <div class="user-content">
        <ng-content></ng-content>
      </div>
      <div *ngIf="!hideAvatar" class="user-thumbnail-container">
        <img class="user-thumbnail" [src]="profile.avatarUrl && profile.avatarUrl.startsWith('/') ? env.apiBaseUrl + profile.avatarUrl : profile.avatarUrl" alt="user profile image thumbnail" />
      </div>
    </div>
  `,
})
export class ProfileWidgetComponent implements OnDestroy {
  @Input() hideAvatar = false;
  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  profile?: IUserProfileWidgetInfo;
  private subscription?: Subscription;

  constructor(private store: Store<{ onboarding: OnboardingState }>, @Inject(CA_ENVIRONMENT) public env: CaEnvironment) {
    this.subscription = this.store.select(selectUser).subscribe((res) => {
      if (res && res.profile) {
        this.profile = {
          avatarUrl: res.profile.avatar,
          firstname: res.profile.firstName,
          lastname: res.profile.lastName,
        };
      } else this.profile = undefined;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
