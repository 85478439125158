import { Component, Input } from '@angular/core';
import { IUserProfile } from '../../types';

@Component({
  selector: 'ca-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  styleUrls: ['./user-profile-card.component.scss'],
})
export class UserProfileCardComponent {
  @Input()
  profile!: IUserProfile;
}
