import { Directive, OnDestroy } from '@angular/core';

import { OnboardingService } from '../services/onboarding.service';
import { CaSubscriber } from '@ca/ca-utils';
import { CaDataService } from '@ca/ca-data';

@Directive({
  selector: '[caLogoutOnUnauthorized]',
})
export class LogoutOnUnauthorizedDirective implements OnDestroy {
  sub: CaSubscriber = new CaSubscriber();
  constructor(private dataService: CaDataService, private onboarding: OnboardingService) {
    this.sub.subscribe(this.dataService.unauthorizedEvent, {
      next: () => {
        console.log('unauthorized access to data blocked');
        this.onboarding.logout();
      },
    });
  }
  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }
}
