import { Observable, Observer, PartialObserver, Subscription } from 'rxjs';

export class CaSubscriber {
  subscriptions: Subscription[] = [];

  subscribe<T>(
    observable: Observable<T>,
    observer: PartialObserver<T> | Observer<T>
  ) {
    this.subscriptions.push(observable.subscribe(observer));
  }

  closeSubscriptions() {
    this.subscriptions.forEach((e) => {
      if (!e.closed) e.unsubscribe();
    });
    this.subscriptions = [];
  }
  closeSubscription(index: number) {
    const sub = this.subscriptions[index];
    sub.unsubscribe();
    this.subscriptions = this.subscriptions.splice(index, 1);
  }
}
