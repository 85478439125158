import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { firstValueFrom, switchMap } from 'rxjs';
import {
  Login,
  LoginAdminSuccess,
  LoginMemberSuccess,
  LoginFailure,
  LogoutSuccess,
  Logout,
  LogoutFailure,
  RefreshToken,
  RefreshTokenSuccess,
  RefreshTokenFailure,
  UnauthorizedPageLoad,
  UnauthorizedHttpRequest,
} from './user.reducer';
import { AuthService } from '../auth.service';
import { CaSubscriber } from '@ca/ca-utils';
import { AdminSignInResultDTO, MemberSignInResultDTO, VNSEnvironment } from '@ca/vns-models';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as moment from 'moment';
import { VNS_ENVIRONMENT } from '../config';
import { VNSCoreConfig } from '../VNSCoreConfig';
import { SocketsService } from '../sockets.service';

// https://www.elvisduru.com/blog/nestjs-jwt-authentication-refresh-token
@Injectable()
export class LoginEffects implements OnDestroy {
  private sub = new CaSubscriber();
  constructor(
    @Inject(VNS_ENVIRONMENT)
    private readonly env: VNSEnvironment,
    @Inject(VNSCoreConfig) private readonly config: VNSCoreConfig,
    private readonly actions$: Actions,
    private auth: AuthService,
    private router: Router,
    private readonly ws: SocketsService,
    private store: Store
  ) {
    this.sub.subscribe(
      this.actions$.pipe(ofType(LoginAdminSuccess, LoginMemberSuccess)),
      this.loginObserver
    );
    this.sub.subscribe(
      this.actions$.pipe(ofType(LogoutSuccess, UnauthorizedPageLoad, UnauthorizedHttpRequest)),
      this.logoutObserver
    );
    this.sub.subscribe(this.actions$.pipe(ofType(RefreshTokenSuccess)), this.refreshObserver);
    this.sub.subscribe(this.actions$.pipe(ofType(Logout)), this.logoutObserver);
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
    this.ws.disconnect();
  }

  onLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Login),
      switchMap((dto) =>
        firstValueFrom(this.auth.login(dto))
          .then((res) =>
            this.config.role === 'admin'
              ? LoginAdminSuccess(res as AdminSignInResultDTO)
              : LoginMemberSuccess(res as MemberSignInResultDTO)
          )
          .catch((error) => LoginFailure({ error }))
      )
    )
  );

  onLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Logout),
      switchMap(() =>
        this.auth
          .logoutRequest()
          .then((res) => LogoutSuccess())
          .catch((err) => LogoutFailure({ error: err }))
      )
    )
  );

  onRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshToken),
      switchMap(() =>
        this.auth
          .refreshToken()
          .then((res) => RefreshTokenSuccess(res))
          .catch((err) => RefreshTokenFailure({ error: err }))
      )
    )
  );

  // onUnauthorized$ = createEffect(() => this.actions$.pipe(ofType()))

  private logoutObserver = {
    next: () => {
      // clear bearer token
      sessionStorage.removeItem(this.env.sessionStorageBearerKey);
      console.log('NAVIGATING TO LOGIN');
      this.auth._logout();
      this.ws.disconnect();
      // don't do this if route is login
      this.router.navigate(['login']);
    },
  };

  /**
   * Sets bearer token in session storage and redirects to success route.
   */
  private loginObserver = {
    next: async (res: AdminSignInResultDTO | MemberSignInResultDTO) => {
      if (res.access_token) this.auth.setAccessToken(res.access_token);
      if (res.refresh_token) this.auth.setRefreshToken(res.refresh_token);
      this.router.navigate(this.config.role === 'admin' ? ['/dossiers'] : ['/mijn-dossier']);
    },
  };

  private refreshObserver = {
    next: async (res: { access_token: string; refresh_token: string }) => {
      if (res.access_token) {
        console.log('Refreshed token successfully', moment().toISOString());
        this.auth.setAccessToken(res.access_token);
        this.auth.setRefreshToken(res.refresh_token);
      }
    },
  };
}
// import { Inject, Injectable, OnDestroy } from '@angular/core';
// import { Actions, createEffect, ofType } from '@ngrx/effects';
// import { firstValueFrom, switchMap } from 'rxjs';
// import {
//   Login,
//   LoginSuccess,
//   LoginFailure,
//   LogoutSuccess,
//   Logout,
//   LogoutFailure,
//   RefreshToken,
//   RefreshTokenSuccess,
//   RefreshTokenFailure,
//   UnauthorizedPageLoad,
//   UnauthorizedHttpRequest,
// } from '../reducers/user.reducer';
// import { AuthService } from '../../services/auth.service';
// import { CA_ENVIRONMENT } from '@ca/ca-ng-core';
// import { CaEnvironment, CaSubscriber } from '@ca/ca-utils';
// import { AdminSignInResultDTO } from '@ca/vns-models';
// import { Router } from '@angular/router';
// import { Store } from '@ngrx/store';

// import * as moment from 'moment';

// // https://www.elvisduru.com/blog/nestjs-jwt-authentication-refresh-token
// @Injectable()
// export class LoginEffects implements OnDestroy {
//   private sub = new CaSubscriber();
//   constructor(
//     @Inject(CA_ENVIRONMENT)
//     private readonly env: CaEnvironment & {
//       appUrl: string;
//       backendUrl: string;
//       recaptchaSiteKey: string;
//     },
//     private readonly actions$: Actions,
//     private auth: AuthService,
//     private router: Router,
//     private store: Store
//   ) {
//     this.sub.subscribe(this.actions$.pipe(ofType(LoginSuccess)), this.loginObserver);
//     this.sub.subscribe(
//       this.actions$.pipe(ofType(LogoutSuccess, UnauthorizedPageLoad, UnauthorizedHttpRequest)),
//       this.logoutObserver
//     );
//     this.sub.subscribe(this.actions$.pipe(ofType(RefreshTokenSuccess)), this.refreshObserver);
//   }

//   ngOnDestroy(): void {
//     this.sub.closeSubscriptions();
//   }

//   onLogin$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(Login),
//       switchMap((dto) =>
//         firstValueFrom(this.auth.login(dto))
//           .then((res) => LoginSuccess(res))
//           .catch((error) => LoginFailure({ error }))
//       )
//     )
//   );

//   onLogout$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(Logout),
//       switchMap(() =>
//         this.auth
//           .logoutRequest()
//           .then((res) => LogoutSuccess())
//           .catch((err) => LogoutFailure({ error: err }))
//       )
//     )
//   );

//   onRefresh$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(RefreshToken),
//       switchMap(() =>
//         this.auth
//           .refreshToken()
//           .then((res) => RefreshTokenSuccess(res))
//           .catch((err) => RefreshTokenFailure({ error: err }))
//       )
//     )
//   );

//   // onUnauthorized$ = createEffect(() => this.actions$.pipe(ofType()))

//   private logoutObserver = {
//     next: () => {
//       // clear bearer token
//       sessionStorage.removeItem(this.env.sessionStorageBearerKey);
//       console.log('NAVIGATING TO LOGIN');
//       this.auth._logout();
//       // don't do this if route is login
//       this.router.navigate(['login']);
//     },
//   };

//   /**
//    * Sets bearer token in session storage and redirects to success route.
//    */
//   private loginObserver = {
//     next: async (res: AdminSignInResultDTO) => {
//       if (res.access_token) this.auth.setAccessToken(res.access_token);
//       if (res.refresh_token) this.auth.setRefreshToken(res.refresh_token);
//       this.router.navigate(['/dossiers']);
//     },
//   };

//   private refreshObserver = {
//     next: async (res: { access_token: string; refresh_token: string }) => {
//       if (res.access_token) {
//         console.log('Refreshed token successfully', moment().toISOString());
//         this.auth.setAccessToken(res.access_token);
//         this.auth.setRefreshToken(res.refresh_token);
//       }
//     },
//   };
// }
