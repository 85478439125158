import { Component, Inject, inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { ActionSnackbarData } from '../../snackbar.service';

@Component({
  selector: 'ca-action-snackbar',
  template: `
    <div class="ca-snackbar" [style]="{ backgroundColor: containerColor }">
      <span matSnackBarLabel>{{ data.message }}</span>
      <span matSnackBarActions>
        <ng-container *ngFor="let action of data.actions">
          <button
            class="snackbar-action"
            mat-button
            *ngIf="!action.routerLink"
            (click)="snackBarRef.dismiss()">
            {{ action.text }}
          </button>
          <a
            class="snackbar-action"
            mat-button
            *ngIf="action.routerLink"
            [routerLink]="action.routerLink"
            >{{ action.text }}</a
          >
        </ng-container>
      </span>
    </div>
  `,
  styles: [
    `
      .ca-snackbar {
        display: flex;
      }
      .snackbar-action {
        color: white;
      }
    `,
  ],
})
export class ActionSnackbarComponent implements OnInit {
  snackBarRef = inject(MatSnackBarRef<ActionSnackbarComponent>);
  containerColor = '#333333'; // '#8bffd2';
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ActionSnackbarData) {}

  ngOnInit(): void {
    console.log('snackbar init!', this.data);
  }
}
