// export class ResourceServiceConfig {
//   constructor(
//     options: Partial<ResourceServiceConfig> & {
//       resourceName: string;
//       route: string;
//     }
//   ) {
//     this.resourceName = options.resourceName;
//     this.route = options.route;
//     // optional
//     this.idName = options.idName ?? 'id';
//     this.isWrappedGetResponse = options.isWrappedGetResponse ?? true;
//     this.allowDelete = options.allowDelete ?? true;
//     this.allowGet = options.allowGet ?? true;
//     this.allowPost = options.allowPost ?? false;
//     this.allowPut = options.allowPut ?? true;
//     this.requireSafeDelete = options.requireSafeDelete ?? true;
//   }
//   idName: string;
//   resourceName: string;
//   route: string;
//   isWrappedGetResponse: boolean;
//   // usage restrictions
//   allowGet: boolean;
//   allowDelete: boolean;
//   requireSafeDelete: boolean;
//   allowPut: boolean;
//   allowPost: boolean;
// }

export class ResourceServiceConfig {
  idName: string = 'id';
  resourceName!: string;
  route!: string; //prepend with slash!
  isWrappedGetResponse: boolean = true;
  // usage restrictions
  allowGet: boolean = true;
  allowDelete: boolean = true;
  requireSafeDelete: boolean = true;
  allowPut: boolean = true;
  allowPost: boolean = false;
}
