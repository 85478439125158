import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AreYouSureOverlayComponent,
  IAreYouSureOverlay,
} from '@ca/ca-are-you-sure';
import { OnboardingModuleConfiguration } from '../../../types';

@Component({
  selector: 'ca-logout',
  template: `
    <ca-are-you-sure-overlay
      [confirmQuestion]="confirmQuestion"
      [confirmButtonText]="confirmButtonText"
      [icon]="icon"
    ></ca-are-you-sure-overlay>
  `,
})
export class LogoutComponent implements Partial<IAreYouSureOverlay> {
  public confirmQuestion: string;
  public confirmButtonText: string;
  public icon?: string;
  constructor(
    private config: OnboardingModuleConfiguration,
    public dialog: MatDialogRef<AreYouSureOverlayComponent>
  ) {
    this.confirmQuestion = this.config.messages.logout.confirmQuestion;
    this.confirmButtonText = this.config.messages.logout.confirmButtonText;
    this.icon = 'logout';
  }
}
