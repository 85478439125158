import { Injectable } from '@angular/core';

import { ICacheableData } from './ICacheableData';
import { LoggingService } from '@ca/ca-ng-core';

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  constructor(private logger: LoggingService) {}

  clear(urls: string[]): void {
    urls.forEach((resourceUrl) => {
      let n = sessionStorage.length;
      while (n--) {
        const key = sessionStorage.key(n);
        if (key?.startsWith(resourceUrl)) {
          sessionStorage.removeItem(key);
        }
      }
    });
  }

  cache<T>(data: ICacheableData<T>): void {
    sessionStorage.setItem(data.key, JSON.stringify(data.data));
  }

  get<T>(key: string): T | null {
    const cacheString: string | null = sessionStorage.getItem(key);
    if (cacheString == null) return null; // throw new Error('cache key does not exist!');
    return JSON.parse(cacheString) as T;
  }
}
