import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { OnboardingAction } from '../../../store/config';
import { twoFaRequest } from '../../../store/actions';
import { isOnboardingError } from '../../../store/utils';
import {
  ILoginSuccessApiResponse,
  ITwoFaDialogMessages,
  LoginSuccess,
  OnboardingError,
  OnboardingModuleConfiguration,
  OnboardingState,
  TwoFactorDialogResult,
  TwoFactorForm,
} from '../../../types';
import { LoggingService } from '@ca/ca-ng-core';
import { SnackbarService } from '@ca/ca-snackbar';
import { CaSubscriber } from '@ca/ca-utils';

@Component({
  selector: 'ca-two-factor-auth-setup',
  template: `
    <div class="two-fa-setup">
      <h1 mat-dialog-title>{{ msgs.ui.title }}</h1>
      <div mat-dialog-content>
        <!-- AUTHENTICATOR SETUP -->
        <p *ngIf="data.twoFaDeviceLinked === true">
          {{ msgs.ui.input_code_label }}
        </p>
        <p *ngIf="data.twoFaDeviceLinked === false">
          {{ msgs.ui.qr_code_label }}
        </p>
        <img
          *ngIf="data.twoFaDeviceLinked === false"
          [src]="data.twoFaQrCodeUrl"
        />
        <br *ngIf="data.twoFaDeviceLinked === false" />
        <div [formGroup]="form.formGroup">
          <mat-form-field>
            <mat-label>{{ msgs.ui.code_input_label }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="authenticatorCode"
              (keyup.enter)="this.onProceed()"
            />
          </mat-form-field>
        </div>
      </div>

      <!-- IMAGE LOAD ERROR SCREEN -->
      <div
        *ngIf="!data.twoFaQrCodeUrl && data.twoFaDeviceLinked === false"
        mat-dialog-content
      >
        <h1>{{ msgs.ui.whoops }}</h1>
        <p>
          {{ msgs.ui.fetch_code_error }}
        </p>
      </div>

      <div class="dialog-actions" mat-dialog-actions>
        <button
          mat-button
          [mat-dialog-close]="{ success: false }"
          class="warning go-back-button"
        >
          {{ msgs.ui.back_button_text }}
        </button>
        <button
          mat-button
          cdkFocusInitial
          (click)="onProceed()"
          class="success"
        >
          {{ msgs.ui.proceed_button_text }}
        </button>
      </div>
    </div>
  `,
})
export class TwoFactorAuthSetupComponent implements OnDestroy {
  msgs: ITwoFaDialogMessages;
  sub: CaSubscriber = new CaSubscriber();
  form: TwoFactorForm;

  private resultObserver = {
    next: (res: LoginSuccess | OnboardingError) => {
      this.form.reset();
      if (isOnboardingError(res)) {
        // DO ERROR STUFF
        this.logger.logError(res);
        this.snackbar.errorSnackbar(res.message);
      } else {
        this.dialogRef.close({
          success: true,
          profile: res.profile,
        });
      }
    },
  };

  constructor(
    private config: OnboardingModuleConfiguration,
    private snackbar: SnackbarService,
    private actions$: Actions,
    private store: Store<{ onboarding: OnboardingState }>,
    public dialogRef: MatDialogRef<
      TwoFactorAuthSetupComponent,
      TwoFactorDialogResult
    >,
    private logger: LoggingService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ILoginSuccessApiResponse
  ) {
    this.msgs = this.config.messages.twoFa;
    this.form = new TwoFactorForm(this.fb);

    this.sub.subscribe(
      this.actions$.pipe(
        ofType(OnboardingAction.TWO_FA_SUCCESS, OnboardingAction.TWO_FA_FAILURE)
      ),
      this.resultObserver
    );
  }

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  onProceed(): void {
    this.form.markAsTouched(); // show validation on form input
    if (this.form.valid)
      this.store.dispatch(twoFaRequest({ data: this.form.data }));
  }
}
