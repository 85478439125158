import { Injectable } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadPagesInfo, LoadPagesInfoFailure, LoadPagesInfoSuccess } from '../reducers/app.reducer';
import { switchMap } from 'rxjs';

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions, private cms: CmsService) {}
  onLoadPagesInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadPagesInfo),
      switchMap(() =>
        this.cms
          .fetchMenu()
          .then((res) => LoadPagesInfoSuccess({ pages: res.pages }))
          .catch((err) => LoadPagesInfoFailure({ error: err }))
      )
    )
  );
}
