import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadTickets, MyFileState } from '../../store/reducers/my-file.reducer';
import { Observable, firstValueFrom } from 'rxjs';
import { Page, PageID, PageInfo, TicketSummary } from '@ca/vns-models';
import { LoadPage, PagesState } from '../../store/reducers/pages.reducer';
import { AppState } from '../../store/reducers/app.reducer';
import { CaSubscriber } from '@ca/ca-utils';

@Component({
  selector: 'ca-my-file',
  template: `<div class="header">
      <h1>Mijn dossiers</h1>
      <button [routerLink]="['/nieuw-dossier']">+ Nieuwe dossieraanvraag</button>
    </div>
    <mat-nav-list>
      <a *ngFor="let ticket of tickets | async" [routerLink]="['/' + metaUrl, ticket.id]">
        <div class="list-item-line">
          <h3>{{ ticket.title }}</h3>
          <span>
            <mat-chip>{{ ticket.status }}</mat-chip>
            &nbsp;
            <!-- TODO: add mat badge with notifications (add notification count to TicketSummary DTO) -->
            <mat-icon>notifications</mat-icon>
          </span>
        </div>
      </a>
    </mat-nav-list> `,
  styles: [
    `
      a {
        text-decoration: none;
        color: whitesmoke;
      }
      .list-item-line {
        display: flex;
        padding: 0.75rem 0rem;
        align-items: center;
        gap: 0.5rem;
        border-bottom: 1px solid #d1d9e2;
        justify-content: space-between;
      }
    `,
  ],
})
export class MyFileComponent implements OnInit, OnDestroy {
  // TODO: load CMS data for
  private static pid: PageID = PageID.MijnDossier;
  private sub = new CaSubscriber();
  protected tickets: Observable<TicketSummary[]> = this.store.select((s) => s.myFile.tickets);
  private loaded = false;
  private pageInfo?: PageInfo;
  protected data: Page | null = null;
  get metaUrl() {
    return this.pageInfo?.metaUrl ?? '';
  }
  private pageDataObserver = {
    next: (pageData: Page | null) => {
      if (!this.pageInfo) throw new Error('No info found for page!!!');
      if (pageData === null) {
        if (!this.loaded) {
          this.store.dispatch(
            LoadPage({
              metaUrl: this.pageInfo.metaUrl,
            })
          );
          this.loaded = true;
        } else throw new Error('Page data could not be retreived');
      } else {
        this.data = pageData;
      }
    },
  };

  constructor(private store: Store<{ myFile: MyFileState; app: AppState; pages: PagesState }>) {}

  ngOnDestroy(): void {
    this.sub.closeSubscriptions();
  }

  ngOnInit(): void {
    this.store.dispatch(LoadTickets());

    firstValueFrom(
      this.store.select((s) => s.app.protectedRoutes.find((e) => e.id === MyFileComponent.pid))
    ).then((res) => {
      this.pageInfo = res;
      if (res)
        this.sub.subscribe(
          this.store.select((s) => s.pages[res.metaUrl]),
          this.pageDataObserver
        );
    });
  }
}
