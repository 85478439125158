import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AreYouSureOverlayConfiguration } from './AreYouSureOverlayConfig';
import { IAreYouSureOverlay } from './interfaces/IAreYouSureOverlay';
import { CaEnvironment } from '@ca/ca-utils';
import { CA_ENVIRONMENT } from '@ca/ca-ng-core';

@Component({
  selector: 'ca-are-you-sure-overlay',
  template: `
    <h1 mat-dialog-title class="inline">
      {{ title }}
      <span class="spacer"></span>
      <mat-icon *ngIf="icon" class="mat-icon-inline">{{ icon }}</mat-icon>
    </h1>
    <div mat-dialog-content>
      {{ confirmQuestion }}
      <ng-content></ng-content>
    </div>
    <div mat-dialog-actions align="end">
      <a class="warning" (click)="close(false)">
        {{ cancelButtonText }}
      </a>
      <button mat-raised-button class="danger" (click)="close(true)">
        {{ confirmButtonText }}
      </button>
    </div>
  `,
  styles: [
    `
      .spacer {
        flex: 1 1 auto;
      }

      .inline {
        display: flex;
        flex-direction: row;
        /* height: 2em; */
        margin: 1em;
      }
    `,
  ],
})
export class AreYouSureOverlayComponent implements Partial<IAreYouSureOverlay> {
  @Output() userChoice: EventEmitter<boolean> = new EventEmitter<boolean>();

  //TODO: move defaults to configuration
  @Input() public title?: string;
  @Input() public confirmButtonText?: string;
  @Input() public cancelButtonText?: string;
  @Input() public confirmQuestion?: string;
  @Input() public icon?: string;
  @Input() public preventDefault = false;

  constructor(public config: AreYouSureOverlayConfiguration, public dialogRef: MatDialogRef<AreYouSureOverlayComponent, boolean>, @Inject(CA_ENVIRONMENT) protected environment: CaEnvironment) {
    if (!this.title) this.title = config.defaults.title ?? $localize`:@@ays-default-title:confirmation`;
    if (!this.confirmButtonText) this.confirmButtonText = config.defaults.confirmButtonText ?? $localize`:@@ays-default-confirm-btn:Delete`;
    if (!this.cancelButtonText) this.cancelButtonText = config.defaults.cancelButtonText ?? $localize`:@@ays-default-cancel-btn:Cancel`;
    if (!this.confirmQuestion) this.confirmQuestion = config.defaults.confirmQuestion ?? $localize`:@@ays-default-question:Are you sure you want to delete this item?`;
  }

  validate(choice: boolean): boolean {
    return true;
  }

  close(result: boolean): void {
    if (!result || this.validate(result)) {
      if (!this.preventDefault) {
        this.dialogRef.disableClose = false;
        this.dialogRef.close(result);
      }
      this.userChoice.emit(result);
    }
  }
}
